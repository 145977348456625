import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import PageScrollTop from './components/pageToTop/PageScrollTop'


import Finance from "./pages/Finance";


import AboutUs from "./pages/AboutUs";

// Elements import Here 

import Contact from "./elements/contact/Contact";


import BlogGridSidebar from "./components/blog/BlogGridSidebar";

import BlogDetails from "./pages/BlogDetails";
import GallerPage from "./pages/AstralGallery";
import AirFre  from "./pages/AirFreight.js";
import SeaFre  from "./pages/SeaFreight.js";
import RoadFre  from "./pages/RoadFreight.js";
import WareFre  from "./pages/Warehouse.js";
import GetOffer from "./pages/OfferPage.js";
import Firstsection  from './pages/panel';
import { LanguageProvider } from './LanguageContext';
import { MaterialUIControllerProvider } from "./context";
import { AuthContextProvider } from "./context/AuthContext"
import { BrowserRouter } from "react-router-dom";

import Error from "./pages/Error";


// Import Css Here 
import './assets/scss/style.scss';

function myCustomMethod() {
  return (
    <BrowserRouter>
      <MaterialUIControllerProvider>
        <AuthContextProvider>
          <Firstsection />
        </AuthContextProvider>
      </MaterialUIControllerProvider>
    </BrowserRouter>
  );
}


  
  const App = () => {

    const [isAdminPanel, setIsAdminPanel] = useState(false);

    useEffect(() => {
      if (window.location.pathname.includes('/admin') || window.location.pathname.includes('/login')) {
        setIsAdminPanel(true);
     

      } else {
   
        setIsAdminPanel(false);
      }
    }, []);

    return (
      <LanguageProvider>

      <div>
      {isAdminPanel ? (
       myCustomMethod()
      ) : (
          <Router>
          <PageScrollTop>
            <Routes>
              <Route path="/" element={<Finance/>}/> 
              {/* Blog Part  */}
              <Route path="/news" element={<BlogGridSidebar/>}/>
              <Route path="/blog-detay/:id" element={<BlogDetails/>}/>
              <Route path="/iletisim" element={<Contact/>}/>
              <Route path="/tarihce" element={<AboutUs/>}/>
              <Route path="/gallery" element={<GallerPage/>}/>
              <Route path="/air-freight" element={<AirFre/>}/>
              <Route path="/sea-freight" element={<SeaFre/>}/>
              <Route path="/road-freight" element={<RoadFre/>}/>
              <Route path="/warehouse" element={<WareFre/>}/>
              <Route path="/getoffer" element={<GetOffer/>}/>

              <Route path="/error" element={<Error/>}/>

        
              {/* Hata yönlendirmesi - Yukarıdaki yolların dışındaki herhangi bir yol için */}
              <Route path="/*" element={<Error />} />
            </Routes>
          </PageScrollTop>
        </Router>
        )}
      </div>
      </LanguageProvider>

    )
}


export default App
