

import React, { useEffect, useRef,useState,useContext} from "react";
import createGlobe from "cobe";
import { a, useSpring } from 'react-spring';
import LanguageContext from '../../LanguageContext';
const WorldMapOne = () => {
  const { translations } = useContext(LanguageContext);

  const canvasRef = useRef();
  
  const [totalWitdh, settotalWitdh] = useState(0); // canvas genişliğini tutacak state
  const [secondtotalWitdh, setsecondtotalWitdh] = useState(0); // canvas genişliğini tutacak state
  const [thirdtotalWitdh, setthirdtotalWitdh] = useState(0); // canvas genişliğini tutacak state

  const scrollDown = () => {
    window.scrollTo({ top: 672, left: 0, behavior: 'smooth' });
  };

  const pointerInteracting = useRef(null);
  const pointerInteractionMovement = useRef(0);
  const [{ r }, api] = useSpring(() => ({
    r: 0,
    config: {
      mass: 1,
      tension: 280,
      friction: 40,
      precision: 0.001,
    },
  }));
  useEffect(() => {
    let width = 0;
    let phi = 0;
    let theta = 0;
    const onResize = () => {
      const windowWidth = window.innerWidth; 

      if (canvasRef.current) {
       
        let adjustedWidth;
        let second ;
        adjustedWidth = windowWidth * 1.084;
        settotalWitdh(adjustedWidth); // canvas genişliğini güncelle

        if (windowWidth < 529) {
          setthirdtotalWitdh(adjustedWidth-1400);
          setsecondtotalWitdh(115);
        } 
        else{
          setsecondtotalWitdh(windowWidth/3.2);
          setthirdtotalWitdh(windowWidth*1.43);

        }

     


      }
    };
    window.addEventListener('resize', onResize)
    onResize()
    const globe = createGlobe(canvasRef.current, {
      devicePixelRatio: 1,
      width: totalWitdh,
      height: totalWitdh,
      phi: 0,
      theta: 0,
      dark: 1,
      diffuse: 1.2,
      scale:1,
      mapSamples: 14000,
      mapBrightness: 4,
      mapBaseBrightness:0,
      baseColor: [0.6, 0, 0],     // Base color (white)
      markerColor: [0.6, 0, 0],   // Marker color (red)
      glowColor: [0.6, 0, 0],
      // Glow color (red)
      markers: [],
      offset: [secondtotalWitdh, thirdtotalWitdh],
      onRender: (state) => {
        if (!pointerInteracting.current) {
          // Called on every animation frame.
          // `state` will be an empty object, return updated params.
          phi += 0.007;
        } 
        state.phi = phi + r.get()
      },
    });

    return () => {
      window.removeEventListener('resize', onResize); // Event listener'ı temizle
      globe.destroy();
    };
  }, [totalWitdh,secondtotalWitdh,thirdtotalWitdh]);
  const handlePointerMove = (e, isTouch = false) => {
    if (pointerInteracting.current !== null) {
      const clientX = isTouch ? e.touches[0].clientX : e.clientX;
      const clientY = isTouch ? e.touches[0].clientY : e.clientY;
      const deltaX = clientX - pointerInteractionMovement.current.x;
      pointerInteractionMovement.current = { x: clientX, y: clientY };
      
      // Yatay hareket için düzeltilmiş damping faktörü
      const horizontalMovement = deltaX / 500; // Daha düşük bir değer, daha yavaş hareket sağlar
      
      api.start({
        r: horizontalMovement, // yatay hareket için
        t: 0, // dikey hareketi sıfırla
      });
    }
  };

  return (
    <div className="worldmapcontainer">
      <div className="canvas-container" style={{ position: "relative" }}>
      <div className="basee-container">
  <h1 className="newakefont ar">ASTRAL WORLDWIDE LOGISTICS</h1>
  <h1 className="newakefont bar">
  {translations.maintitle}
</h1>  <div style={{ paddingTop: '40px' }}>
  <a href="#" class="btn btn-1 newakefont" onClick={scrollDown}>
      <svg>
        <rect x="0" y="0" fill="none" width="100%" height="100%"/>
      </svg>
      {translations.mainbutton}
      </a>
    </div>
</div>
<canvas
ref={canvasRef}
onPointerDown={(e) => {
        pointerInteracting.current =
          e.clientX - pointerInteractionMovement.current;
        canvasRef.current.style.cursor = 'grabbing';
      }}
      onPointerUp={() => {
        pointerInteracting.current = null;
        canvasRef.current.style.cursor = 'grab';
      }}
      onPointerOut={() => {
        pointerInteracting.current = null;
        canvasRef.current.style.cursor = 'grab';
      }}
      onMouseMove={(e) => {
        if (pointerInteracting.current !== null) {
          const delta = e.clientX - pointerInteracting.current;
          pointerInteractionMovement.current = delta;
          api.start({
            r: delta / 200,
          });
        }
      }}
      onTouchMove={(e) => {
        if (pointerInteracting.current !== null && e.touches[0]) {
          const delta = e.touches[0].clientX - pointerInteracting.current;
          pointerInteractionMovement.current = delta;
          api.start({
            r: delta / 100,
          });
        }
      }}
style={{
  width: "100%",
  height: "700px",
  maxWidth: "100%",
  cursor: 'grab',
  aspectRatio: 1,
  transition: "opacity 1s ease 0s",
}}
/>

      </div>
    </div>
  );
};

export default WorldMapOne;