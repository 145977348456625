import React, { useEffect, useState,useRef } from 'react';
import { Link } from 'react-router-dom';
import SEO from '../common/SEO';

import HeaderTopBar from '../common/header/HeaderTopBar';
import HeaderOne from '../common/header/HeaderOne';


import WorldMapOne from '../elements/worldmaps/worldmapone';
import AstralServiceDsg from '../elements/astralservice/astralservo';
import AstralBlog from '../elements/astralservice/astralblogfrontal';
import AstralContactt from '../elements/astralservice/astralconta';




import FooterFour from '../common/footer/FooterFour';
import FooterTwo from '../common/footer/FooterTwo';


import BlogClassicData from '../data/blog/BlogList.json';

var BlogListData = BlogClassicData.slice(0, 3);

function Finance() {

  return (
    <>
      <SEO title="Finance" />
      <main className="page-wrapper">
        <div>
        <HeaderTopBar></HeaderTopBar>
          <HeaderOne btnStyle="btn-small btn-icon" HeaderSTyle="rn-header header-default header-not-transparent header-default " Loco="main"/>
        </div>
        <WorldMapOne></WorldMapOne>
        <AstralServiceDsg></AstralServiceDsg>
        <AstralContactt></AstralContactt>
        <AstralBlog></AstralBlog>
        <FooterFour></FooterFour>

      </main>
    </>
  );
}

export default Finance;

