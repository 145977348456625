import React, { useContext } from 'react';
import ContactForm from "./ContactForm";
import { FiHeadphones , FiMail , FiMapPin } from "react-icons/fi";
import LanguageContext from '../../LanguageContext';

const ContactOne = () => {
    const { translations } = useContext(LanguageContext);

    return (
        <>
            <div className="row row--15">
                <div className="col-lg-12">
                    <div className="rn-contact-address mt_dec--30">
                    <div>
                    <h1 className='newakefont gord'>{translations.contacttitle}</h1>
                    </div>
                    <div class="cesitli-koseler">
                    <div className='red-black-filter'>
                    <iframe
                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3015.807121086392!2d29.21844127600754!3d40.89804567136622!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x14cac4a560bb95cb%3A0x7de3c324ef5aadb7!2s%C3%87ukurova%20Tower!5e0!3m2!1str!2str!4v1702680748996!5m2!1str!2str"
                    width="100%"
                    height="550px"
                    style={{ border: '0', borderRadius: '0px',}} 
                    allowFullScreen=""
                    loading="lazy"
                    referrerPolicy="no-referrer-when-downgrade"
                ></iframe>
                </div>
                </div>
                        <div className="row">
                          
                        </div>
                    </div>
                </div>
            </div>

            <div className="row mt--40 row--15">
                <div className="col-lg-7">
                    <ContactForm formStyle="contact-form-1" />
                </div>
                <div className="col-lg-5 mt_md--30 mt_sm--30 arrayze" >
                <div className="full-width-div" style={{marginTop:"-50px"}}>
                <img  className='side-image' src={process.env.PUBLIC_URL + '/images/bg/phonered.png'} style={{width:"130px", height:"auto"}} alt="Background"/>

                    <div className="inner">
                        <h4 style={{marginBottom:"5px"}}>{translations.contactphone}</h4>
                        <p style={{marginBottom:"0px"}}><a href="tel:+90 506 046 00 14">+90 506 046 00 14</a></p>
                        <p style={{marginBottom:"0px"}}><a href="tel:+90 541 744 77 43">+90 541 744 77 43</a></p>
                    </div>
                </div>

                <div className="full-width-div">
                <img className='side-image'  src={process.env.PUBLIC_URL + '/images/bg/email.png'} style={{width:"110px", height:"auto"}} alt="Background"/>

                    <div className="inner"> 
                        <h4 className="title">{translations.contactemail}</h4>
                        <p><a href="mailto:sales@astralworldwidelog.com">sales@astralworldwidelog.com</a></p>
                     
                    </div>
                </div>

                <div className="full-width-div">
                <a href="https://www.google.com/maps/dir/41.0279982,28.6848108/Yal%C4%B1,+No:,+Ba%C4%9Flar+Cd.+No:86,+%C3%87ukurova+Tower+%2F+34873+Kartal%2F%C4%B0stanbul/@40.9070334,28.6502856,11.25z/data=!4m9!4m8!1m1!4e1!1m5!1m1!1s0x14cac4a560bb95cb:0x7de3c324ef5aadb7!2m2!1d29.2209021!2d40.8980465?entry=ttu" 
                      target="_blank" rel="noopener noreferrer"><img className='side-image' src={process.env.PUBLIC_URL + '/images/bg/gps.png'} style={{width:"80px", height:"auto"}} alt="Background"/>
                      </a>

                    <div className="inner">
                        <h4 className="title">{translations.contactadress}</h4>
                      <p>  <a href="https://www.google.com/maps/dir/41.0279982,28.6848108/Yal%C4%B1,+No:,+Ba%C4%9Flar+Cd.+No:86,+%C3%87ukurova+Tower+%2F+34873+Kartal%2F%C4%B0stanbul/@40.9070334,28.6502856,11.25z/data=!4m9!4m8!1m1!4e1!1m5!1m1!1s0x14cac4a560bb95cb:0x7de3c324ef5aadb7!2m2!1d29.2209021!2d40.8980465?entry=ttu" 
                      target="_blank" rel="noopener noreferrer">Yalı, Topselvi Cd. No:100, 34873 Mai Residence<br /> KARTAL / İSTANBUL</a></p>
                       
                    </div>
                </div>
</div>
            </div>
        </>
    )
}
export default ContactOne;