import React from 'react';
import SEO from "../../common/SEO";
import Layout from "../../common/Layout";
import BreadcrumbOne from "../breadcrumb/BreadcrumbOne";
import SectionTitle from "../sectionTitle/SectionTitle";
import ContactOne from "./ContactOne";
import FooterFour from '../../common/footer/FooterFour';
import HeaderOne from '../../common/header/HeaderOne';

const Contact = () => {
    return (
        <>
            <SEO title="Contact" />
                    {/* Start Contact Area  */}
                                   
  <div>
  <HeaderOne btnStyle="btn-small btn-icon" HeaderSTyle="header-not-transparent" />
</div>
                    <div className="rwt-contact-area">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-12 mb--40">
                                    <SectionTitle   
                                        textAlign = "text-center"
                                        radiusRounded = ""
                                        subtitle = "İletişim Formu"
                                        title = "Tüm soru ve görüşlerinizi iletebilirsiniz."
                                        description = ""
                                    />
                                </div>
                            </div>
                            <ContactOne />
                        </div>
                    </div>
                    {/* End Contact Area  */}
                    <FooterFour />

        </>
    )
}
export default Contact;