import React, { useContext } from 'react';
import SEO from "../../common/SEO";
import Layout from "../../common/Layout";
import BreadcrumbOne from "../../elements/breadcrumb/BreadcrumbOne";
import BlogProp from './itemProp/BlogProp';
import SectionTitle from "../../elements/sectionTitle/SectionTitle";
import HeaderTopBar from '../../common/header/HeaderTopBar';
import HeaderOne from '../../common/header/HeaderOne';
import FooterThree from '../../common/footer/FooterThree';
import { PiNewspaperDuotone } from "react-icons/pi";
import LanguageContext from '../../LanguageContext';
import {Link} from "react-router-dom";

const BlogGridView = () => {
    const { translations } = useContext(LanguageContext);

    return (
        <>
        
    
                <div className="main-content" style={{marginTop:"15px"}}>
                    
              
                    
                    <div className="rn-blog-area ">
              
                    
                        <div className="container">
                        
                            <div className="row mt_dec--30">
                            <div style={{width:"100%;"}}><h1 className="newakefont" style={{fontWeight:"300",fontSize:"65px",marginBottom:"0px"}}>{translations.lowerside}</h1></div>
    
                            <div style={{width:"100%",marginBottom:"20px"}}><span className="newakefont" style={{fontWeight:"300",fontSize:"18px",marginBottom:"20px"}}>{translations.lowersidesub}</span> <Link to="/news">
                            <PiNewspaperDuotone style={{fontSize:"32px",color:"red",marginLeft:"5px"}}/></Link></div>
                                <BlogProp column="col-lg-4 col-md-6 col-12 mt--30" StyleVarProp="box-card-style-default" />
                            </div>
                        </div>
                    </div>
                </div>

        </>
    )
}
export default BlogGridView;