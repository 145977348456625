import {Link} from "react-router-dom";
import React, { useEffect, useState } from 'react';

const SidebarPost = () => {
    const [newData, setData] = React.useState([]);
    useEffect(() => {
        const fetchData = async () => {
          try {
            const response = await fetch(`https://mrtportservices.com:5000/getData?blog=galle&origin=astwrlwde--`);
            const data = await response.json();
    
            if (!response.ok) {
              throw new Error(data.error || 'Fetch failed');
            }
    
              setData(data);
       
    
    
          } catch (error) {
          } finally {
          }
        };
    
        fetchData();
      });
    return (
        <ul>
            {newData.slice(0, 4).map((value) => {
                return(
                    <li key={value.id}>
                        <Link className="d-block" to={process.env.PUBLIC_URL + `/blog-details/${value.id}`}>{value.title}</Link>
                        <span className="cate">{value.categories.slice(0, 1)}</span>
                    </li>
                )
            })}
        </ul>
    )
}

export default SidebarPost
