import React, { useEffect,useContext } from 'react';
import SEO from "../common/SEO";
import HeaderOne from '../common/header/HeaderOne';
import HeaderTopBar from '../common/header/HeaderTopBar';
import FooterThree from '../common/footer/FooterThree';
import BrandThree from '../elements/brand/BrandThree';
import AboutFour from '../elements/about/AboutFour';
import ServiceOne from "../elements/service/ServiceOne";
import SectionTitle from "../elements/sectionTitle/SectionTitle";
import CounterUpFour from "../elements/counterup/CounterUpFour";
import TeamFour from "../elements/team/TeamFour";
import TimelineTwo from "../elements/timeline/TimelineTwo";
import Separator from "../elements/separator/Separator";
import AboutThree from '../elements/about/AboutThree';
import ServiceFive from "../elements/service/ServiceFive";
import Elements from 'elements/gallery/Gallery';
import {Link} from "react-router-dom";
import { FiArrowRight } from "react-icons/fi";
import FooterFour from 'common/footer/FooterFour';
import LanguageContext from '../LanguageContext';

function AboutUs  ()  {
  const { translations } = useContext(LanguageContext);

    return (
        <>
            <SEO title="Hakkımızda" />
            <main className="page-wrapper" style={{width:"100%"}}>
               
  <div>
  <HeaderTopBar />
  <HeaderOne btnStyle="btn-small btn-icon" HeaderSTyle="header-not-transparent" />
</div>
<div class="container">
  <div class="row">
    <div class="col-md-8"><div style={{paddingLeft:"0%",paddingTop:"5%", }}> 
    <svg class="x abpos" viewBox="0 0 800 750" width="100%" height="750px">
        <defs>
            <clipPath id="svgPath">
                <path d="M1362.6,756.7c-4.5-3.6-8.2-0.8-8.7-5.6c-8.1,0.9-20.3-5.2-25.4-4.2c-3.2-2.1-7.4-3.5-8.4-6.8c-1.7,0-6.7,4.3-7.2-0.4
        c-1.3-0.3-1.5,1.4-2.1,2.4c1.4-2.5-4.6-1-5.5-1.4c-1.8-0.9-4-6.7-2.7-0.7c-4.7-1.7-2.4-4.7-7.9-3.5c-1-0.9-2.1-1.9-2-3.5
        c-2.1,5.3-5.9-3.9-7.9,2.5c-3.4-0.7-6.7-1.4-6.1-4.5c-6,0.5-0.4,3-1.8,7c-1.2-1.4-5.1-6.9-2.7-6.6c-0.3-4.1-5.7-0.7-5.2,3.2
        c-4.5-1.1-3.8-5.5-6.9-7.4l103.9,19.3V256.5l-27.6-5.1c1.9,0.1,3.7-0.1,5.5-0.8c2.4,3.9,6.8,1.8,8.2,2.1c1.3,0.2,4.6,1.1,7.3,1.3
        c2.7,0.2,4.8-0.4,3.6-3c-3.9,2.7-15.1-4.9-17.9-0.2c-2.4-6.1-12.9-1.3-13.8-6.6c-0.4,1-0.3,2.5-1.5,2.4c-4.6-3.2-13.3-2.9-21.7-2.4
        c1.6,0.1,3.1,0,3.5,0c0.6,0.9,1.5,1.6,2.7,2.2c1,0,2.1,0,3.3,0c4.3,1.7,6.7,0.8,8.7,2.7l-10-1.9c-0.7-0.3-1.4-0.6-2-0.9
        c-1.5,0-2.8-0.1-4-0.2l-13.3-2.5c-0.1-1-1.2-1.4-2.3-1.6c0.5,0.6,0.8,1,0.9,1.4l-6.3-1.2c-0.9-0.5-1.6-0.9-1.9-1.3
        c0.3-6,9.5,3.9,12.5-0.8c0.6,1,2.3,1.1,3,2c-0.4-0.6-0.6-1.4-0.4-2.4c2.9,3.1,3,2.4,2.8,1c-0.6,0-1.3-0.1-1.9-0.1
        c0.2-0.8,0.9-1,1.8-0.9c-0.1-0.9,0-1.7,0.8-1.8c-0.1,2.5,10.5,5.4,7.6,1.4c-0.1,0-0.1,0-0.2,0.1c-0.2-0.2-0.3-0.4-0.3-0.5
        c-2.4,1.8-4.5-2.6-7.5-4c-1.8-0.9-6.9-0.3-8.5-1.3c-1.8-1-1.6-2.9-3.2-4c-3.6,1,0.6,3.8-2.6,3.6c-0.3-8.1-13.6-3.2-15.8-11
        c8.5,1.2,13.6,5.5,22.4,4.2c3.9,2.8,10.7,5.1,17.9,7.3c-0.1-0.2-0.2-0.4-0.3-0.6c0.5,0.4,1.1,0.7,1.7,1c1.2,0.4,2.4,0.7,3.6,1.1
        c1.8,0.4,3.7,0.8,5.2,1.5c5.5,1.6,10.6,3.2,14.5,5c6.6-2.1,9.9,2.9,16.1,0.8c-0.8-0.5-1.6-0.9-2.4-1.1c0,0.3,0,0.7-0.1,1.1
        c-1.5-2.1-5.9-1.7-9.3-2.2c0.1-0.1,0.2-0.3,0.4-0.4c-0.8-0.1-1.6-0.3-2.4-0.5c0,0,0,0,0,0c-0.1,0.2-0.2,0.4-0.3,0.7
        c-2.9,0.2-2.3-2.6-5.8-1.9c0-0.9,1.4-1.3,2.9-1.3c-0.1,0-0.2-0.1-0.3-0.1c-1.7-0.4-2.3,0.4-4.5,0c-9.6-1.6-15-7.9-19.3-4.8
        c0-1.1,0.8-2.7-0.5-3.1c-8,1.5-26.7-10.3-35.9-8.6c0.2-1.6-0.8-2.6-1.8-3.6c-1.8,1.6-5,0.6-7.6,0.5c-2.8-3.3-8.7-0.6-11.7-5.2
        c-2.7,1.4-6.6,0.3-10.3-0.3c-0.8-0.2-1.9,0.6-0.4,1.3c1.8,0.6,3.6,1.1,5.4,1.7c1.7,0.6,1.3,2.3,3.6,2.6c1-1.8,3.4-0.6,4.9-1.4
        c1.4,6.4,9.7,2.4,13,5.6c-6.6,3.1-16.3,0.8-24.6-1.9c-6.3-2-15.2-6.9-20.6-5.1c-10.7-4.3-19.4-8.8-30.5-6.6c1.4-5.2-10-7.2-11.7-5.2
        c1.1-4.8-3.7-1-3.2-4c3.6-1.9,3.7,0.8,5,3c1.2-2.7,4.4-1.6,6.7-2.4c-0.4,1.7-0.6,3.3,1.8,3.5c10.4-2.4,18.8,7.2,32.7,4.4
        c-1-0.6-2.6-0.9-2.3-2.2c-2.5-1.2-3.3,1-4.9,1.4c1-4.4-8.5-3.6-12.1-3.8c-5.5-2.6-14.3-5.5-19.8-7.7c0.3,0.1-1.5,2.4-2.2,2.3
        c0.3,0-2.9-3.8-1.4-4.9c9,2.4,11.7,1.2,18.4,2.8c2.3,3.6,3.1,4.4,5.8,0.4c-8-3.9-12.7-4.9-23.4-5.9c-2.6-0.5-2.5-2.4-5-3
        c-5.2,0.6-5.5-2.8-8.9,0.2c-6.4-2.6-15.1-5.9-24.7-6.2c-2.5-0.7-2.4-8.3-5.9-4.8c1.7,1.4,2.6,3.1,2.8,5.3c-1.1,0-2.7-0.8-3.1,0.5
        c7.3,1.1,8.4,3.4,13.9,2.8c-0.3,1.6-2.6,1.5-0.8,2.7c3.1-1.9,9.6,2.5,12.1-0.7c4,5.2,12.6,0.4,12.2,8.3c-3.1-2.9-5-2.9-8.9,0.2
        c2.2-2.8-8.9-3.6-4-1.3c2.5,1,4.2,2.3,4.6,4.4c-5.1-3.3-6.1,1.2-10.3-0.2c1.7-3.5,3.2-5.9,2.5-8.1c-0.9,3.5-13.7-4.9-10.3-0.2
        c3.7,1.4,9.6,1.7,8.6,5.6c-8.8,1.9-18,3.2-27.3-2.6c-2.2,0.8-5.4-0.2-7.6,0.6c2.2-5.9-6.8-4.5-10-6c-1.7-0.8-1.6-3.5-3.2-4
        c0,0-0.2,0.1-0.5,0.2c-0.3,0.1-0.8,0.3-1.3,0.5c-1,0.4-2.2,0.8-3.1,0.7c-6.4-0.6-12-3.2-16.2-5c-1.7,1-3.5,1.9-5.3,2.8
        c0.2-2-2.5-2.4-3.2-3.9c-11.1-1.7-20.7-2.3-28.8-7.3c-4.4,0.9,1.7,6.7-2,6.8c-3.1-2.7-3.8-2.4-1.1-6.2c-5.1-2.5-0.5,6.1-7,3.8
        c2.5-1.4,2.3-4-1.5-4.9c-0.8,2-1,5.1-3,5c-5.2-2.7-17.9-6.9-21.1-3.4c0.1-0.1,0.2-0.8,0.4-1.4c0.1-0.3,0.2-0.6,0.3-0.9
        c0.1-0.2,0.1-0.4,0.1-0.4c-8.1-2-17.8-0.8-23-6.9c-4.6,2.5-7.1-2.3-9.5-2.8c-3.1-0.7-7.1,1.8-9.9-1.5c-2.4,0.4-3.3,3.7-6.6,2.5
        c2.1-1.5,2.4-2.9-0.1-4.5c-1.2,3.3-4.7-1-5.4-1.6c-3.6,1.1,0.7,3.8-2.6,3.7c-0.6-8.2-15-5.2-18.1-8.3c5.6-5.2,14.9,1.7,19.8,2.9
        c3.4,0.8,7.8-2.9,8.5,1c0.6-1,0.8-2.7,2.2-2.3c5,0.3,4.6,3.6,9.1,4.2c1.2-3.7-1.4-3.4,1.6-5.4c-4.9-1.3-8.4-0.2-13-0.9
        c-5.3-3.1-19.6-2.4-18.2-8.3c0.1,2.9,8.5,3.5,4.5-0.2c-0.9,2.5-4.7-2.6-6.4-3.4c1.6,8.2-5.9-1.9-2,6.8c-4.7-1-10.7-4.3-13.9-2.6
        c0-1.1,0.8-2.7-0.6-3.1c-0.3,3.2-8.6,2-8.5-1c0.7-2.2,4,0.3,4.9-1.5l-9.5-2.8c-2.8,2.9,4.1,5.8-0.2,5.8c-4.8-2.7-14.9-5.9-23.8-4
        c0.6-2.4,1.5-4.1,2.9-5c-4.2-0.8-5.3,4.1-9.7,3.1c-11.8-5.5-30.1-6.2-41.1-10.4c3.4-3.9,5.8,3.1,8-2.1c-7.6-2-20.6-1.4-23.1-6.6
        c-2.9,2.6-1.2,6-3.3,6.4c-1.9-4.3-12.3-9.8-18.2-8.1c3.4,1.5,7.3,2.8,8.7,5.4c-8.8-0.4-14-1-20.7-4.4c-7.8,1.1-15.8-1.4-20.2-1.3
        c0.8,0.2,1.2,1.5-0.4,1.4c-2.2,0.2-1.8-6.2-3.7-2.5c2,0.9,0.1,3.2-1.1,4.1c6.9-1.2,12.1,5.7,17.4,0.5c1.4,5.7,6.4,2.7,8.9-0.4
        c-0.7,2.3,0.1,3.8,1.6,4.8c5.1,0.2,9.2-1.7,16.7,3.3c1.7-1.7,4.9-0.7,7.6-0.8c-0.4,1.4-1.6,1.3-2.1,2.3c8,0.8,10.5,3,17.4,0.6
        c0.3,2.1,5.2,1.7,4.6,4.3c-6.6-0.3-9.5,1.8-15.3-2.9c-0.6,1-0.8,2.7-2.1,2.3c-5.8-2.7-6.9-1.5-10.5-4.4c-3.5,3,5.2,4.3,0.2,4.5
        c-3.2-0.5-0.4-4.4-2.9-5.2c-10.5,0.5-18.1,5.4-25.6-2.8c-1.7,1.7-3.2,3.7-6.6,2.6c-1.9,2.8-1.1,9.5,1.2,6.2
        c-0.8-0.2-1.2-1.5,0.4-1.3l6.8,1.9c2.8,2.7-3.6,3.4-0.2,5.8c3.5-1.6,2.4-3.9,4.2-4.7c3.3,1.2,4.8-1,7.5-0.8c0.9,1.8,1.9,3.5,2.9,5.2
        c2-1.9,6.2,0.2,8.9-0.4c-3.3,2-2.9,5.4-4.6,6c-1.8-0.5-0.6-2.7-1.9-3.5c-1.2,2.2,1,4-1.2,4.1c-2.8-7-9.5-0.4-15.7-1.5
        c-4-3.6-8.8-0.9-13.2-5.2c-3,1.3-5.6,2.4-6.8-1.9c1.5,0.9,5.8-2.6,2.5-3.7c-0.2,0.8-1.5,1.2-1.4-0.4c2.6,0.6,2.2-4.2,5.2-2.9
        c-0.1,1.6,1.3,3.9,2.3,2.1c-1.5-1.9,1.2-2.1-1.6-4.8c1.7,0.3,3.3,0.5,3.5-2c-8.5,0.6-15.5-7-21.1-3c-2.8-5.2-9.8-2.6-15.5-7.3
        c-0.5,0.5-1,0.9-1.5,1.1c1.1,0.8,0.2,2.7,0,4.3c-1.4-0.6-0.6-2.6-0.3-4.2c-4.4,1.6-9.9-6-11.8-5c4.5,3.7-3.6,1.4-3.8,3.3
        c3.5,0.7,2.5,4,4.3,5.6c-2.5,1.3-2.5,6.3,2.6,6.6c3.4-4.3-4.4-2.1-2.9-5.2c5.7,2.6-0.3-3,3.5-2c3.6,4.2,2.6,0.5,4.4-0.2
        c1.8,4.9,9.8-0.5,10.1,5.7c-2.9-1.1-4.6-0.1-5.2,3c3.1,1.5,3.6-1.7,7.2,0.5c-0.6,1.6-3.3,2.4-1.1,4.1c0.2-0.8,1.5-1.2,1.4,0.4
        c-4.5,1.9-6.7,0.1-11.2-1.7c-0.6,1-0.8,2.7-2.1,2.3c-1.4-3.1-5.8,0.5-7.8-3.6c-0.6,1.6-1.3,3.1-3.5,2c0.5-1.8-2-1.9-2-3.5
        c1.6,0.1,3.9-1.3,2.1-2.3c-1.4,0.9-4.7,0-9.9-1.3c-0.8,4.7-6.5,0.6-9.3,1.8c0.5-2-1.9-7.9-4.7-4.2c0.9,1.8,4.9,1.8,2.9,5.2
        c-2.6-1.1-3.2,1.1-4.8,1.6c-1-6.4-13.9-6.3-16.6-3.1c4.5-5-4.9-2.5-5.3-7.3c5.1,0.5,6.1,3.4,8.8,5.3c1.4-8.5,6.2,2.8,4.6-6.1
        c2.1,3.9,5.9,8.2,9.5,2.6c-3.6-2.3-4.2-3-7.2-0.5c-0.2-2.2-1.2-3.9-3-5.2c-3.7,4.3-12,0.5-16.8,2.8c3.4,1.1,4.7,3.4,3.9,6.9
        c-4,0-2-3.5-4.7-4.2c-1.9,2.1-5.1,1.9-8.9,0.5c1,3.6-4.9,4.2-1.5,5.5c3.5,0.3,8.6-0.7,10.5,4.3c-6.6,0.7-13.4-3.4-17.9-9.2
        c5.7,0,10.4-1.9,13.4-6.6c-2.3-0.2-4.3-0.3-5.9-0.7c2.4,0.3,4,0.2,2.4-2.1c-1.5,1.8-4.1-0.5-6.2-1.4c-0.2-0.4-0.3-0.9-0.4-1.5
        c4.3-0.7,8.8,0.6,12.2,3.3c1.3-1.8,3-2.8,5.2-3c4.2,6.9,17.5,0.4,21.7,5.9c0.8-3.5-1.4-5,2.9-5.1c-2.1,1.5-2.4,2.9,0.2,4.5
        c2.2-3.7,4.1,2,6.8,1.9c0.9-5.8,2.7,1.3,7.2,0.5c0.8-2.3,0.9-4.3,0.1-5.8c-2.4-1.8-3.4,1.9-4.8,1.6c-0.1,0,0.9-2.5,0.7-2.7
        c-2.2-3.8-10.2,1.9-12.6-2c0.2-2-1.6-2.9-1.6-4.8c5.6,1.4,11.2,2.8,14.6,5.5c2.7-0.6,4.9-2.2,7.9-2.2c4.6,5.1,12.1-1.5,14.6,5.5
        c1.9-1.4,3.4-3.5,5.6-4.3c-0.2,7.2,12.5,0.8,11.1,7.5c4.7,1.5,6.7-1.7,10.6-1.4c-1.2,1-4.6,4.1,0.2,4.5c4.8,1.2-1.6-5.3,2.5-3.7
        c1.4,0.4,1.9-0.6,3.1-0.6c-4.1,0.9,1.1,6,2,3.5c-2-0.9-0.1-3.2,1.1-4.1c-26.5-4.2-35.6-10.8-56.2-8.3c-11.8-4.8-33-11.5-47.3-8.5
        c0.2-1.7,1.3-3.8-0.3-4.5c-6.2-0.2-18,0.1-20.4-5.5c-0.6,2.4-2,3.2-3.8,3.4c0.8-1.6,2.9-2.2-0.6-3.1c-5,3.7-9.6,1.3-14-2.3
        c-2.9,4.1-5.7-0.5-8.5-0.8c-1.8-0.2-4.4,1.5-6.2,1.3c-1.7-0.2-1.6-2-3.7-2.4c-6.4-1.3-13-1.9-20.4-5.4c-0.9,3.6-2.9,7.8,0.6,8.9
        c11.7-6.9,30,6.7,39.9,3.2c-1,1.8-2.2,1.9-1.1,4.1c3.8,1.2,4.3-3.3,7.9-2.3c4.4,0.1,4.6,2.7,7.8,3.5c4.6-4,11.2,3.8,18.4,2
        c1.4,1.9,4.7,2.7,6.1,4.6c1.7-1,3.4-2,5.2-3c0.1,4.9,5.6,1.1,7,6.3c-2-10.3,8.5,2.7,12-1.1c-1-0.6-2.7-0.8-2.3-2.1
        c3.2,0.5,5,1.7,4.7,4.2c-11.1,0.3-19.6,0.9-30.6-5.4c-1.4,3.5-4.8,3.6-8.3,3.6c0.7-2.1,3.7-0.1,3.8-3.4c-5.1-2.9-6.6,0.5-9.3,1.9
        c-3.3-2.3-5.8-3.9-8.5-0.8c1.2-4.1-3.9-4.7-7.1-6.3c-0.6,1-0.8,2.7-2.1,2.4c-4.7-2.7-7.6-0.5-14.3-0.9c-4-2.1-5.6-1.3-6.4-3.2
        c-6.2-0.4-12,0.9-15-3.9c-2.4,4.3-3.3,0.4-5.4-1.4c-2.1,6.6-8.2-1-15.7-1.2c-1.5,9.4,7.9,4.7,12.2,9c3.3-0.3,5.9,0.1,12.6,1.9
        c3.1-5.1,10.6,0.1,15.7,1.2c4.8,1,9.4-0.8,9.1,3.9c3.5-1.7,6.3-1.8,6.9-4c-0.4,1.8,1.2,2.4,0.3,4.5c5.7-1.5,7.4,2.4,11.9,4.6
        c0.6-1,0.8-2.7,2.1-2.4c-0.5,2.6,4.4,2.1,4.7,4.2c0.6-1,0.8-2.7,2.1-2.4c-0.4,2,2.4,2.2,3,3.6c-0.2,0.4-0.4,1-0.6,1.7
        c-5.7,1.5,0.9,1.5,1.2,2.9c-2.1,5.8-12.2-2.5-16,0.1c-2.4-0.5-4.1-1.4-5.1-2.8c-0.8,4,3.9,7.7-3.2,6.5c1.6-3.3,3.7-4.7-1.2-6.2
        c-1.2,2.2,1.1,4-1.1,4.1c0.8-6.2-8-3.4-6.7-7.6c-2.6-1.1-3.2,1.1-4.8,1.6c-2.6-5.1-6-2.6-9.8-7c-3.6,5.8-8.6-0.9-12.1,3.8
        c-1.5-1.9-3.3-3.6-4.4-2.4c1.8,0.7,0.3,3.3-0.1,5.3c2-0.2,6,3.2,6.5,0.4c-0.1-0.3-0.4-0.7-0.6-1.1c2.6,1.2,3.2,3.7,4.7,5.6
        c-6.7-0.2-7.4-2.4-12.9-0.5c1.6-5.6-10.5-3-11.1-7.3c5.6,1.7,7.8-2.4,13.3-0.9c0,2.2,1.6,0.6,0.4-1.4c-4.3-0.1-8.8-0.6-11.9,1.3
        c-1.8-4.9-8.4-2.6-12.5-7.6c-1.8,0.9-1.7,4.9-5.1,3c0.4-1.4,1.5-1.3,2.1-2.4c-9.1-2.5-17.2-3.1-25.1-3.6c-0.4-2.4-6.7-1.4-4.4-5.5
        c1.2,3.7,5-1.6,3.4,3.8c4.3,0.7,6.9-1.4,10.6-1.7c6.9,5.3,14.4,1.4,23.1,6c-0.1-7.2-6-3.5-7.8-3.5c-0.1,0-0.6-2.3-2.4-2.1
        c-3.8,0.4-1.6,0.1-5.4-1.4c-3.7,1.5-10.5-2.2-13.3,0.9c0-0.5,2-2.4,2.1-2.4c-1.4-0.6-4.3-1.4-7.6-2c0-0.9-0.5-1.8-1.8-2.3
        c-0.8,0.5-1.2,1.1-1.4,1.8c-6.3-0.9-12.3-0.9-9.4,1.7c0.5-0.6,8-1.3,7.8,3.4c-5.2-0.3-10.4-0.6-16.6-2.8c0,5.3-5.8,0.6-8.8,0.7
        c0.3-1.4,1.5-1.4,2.1-2.4c-3.6-0.9-7.2-1.8-10.9-2.7c0.7,6.5-14.4-3.6-9.5,3.4c4.5-1.9,12.3,2.2,13.6,3.4c-0.6,2.3-4-0.2-4.8,1.7
        c3.4,0.7,6.6,1.4,6.1,4.5c-2.6-0.7-4.7-0.6-6.7-0.2l-17-3.2c0.3-2.4-3.2-2.5-1.4-5.8c-3.3-1-6.5-1.8-8.5-0.6
        c0.4-2.6,0.2-4.8-1.4-6.2c-1,5.4-6.3,3.7-9.8,4.8c4.1-6-2.7-5.3-3.7-8.2c1.7,0.2,3.3,0.4,3.4-2.1c-2.1-3.7-4.7,0.1-6.5-3
        c-1.6,6.4-8.8,3.4-12.9,5.6c0.3-1.7,0.4-3.3-2-3.4c-0.3,3.8-6.6,2.1-6.8-1.7c1.4,2.2,3.9,1.8,5.8,0c-3.3-1-6.5-1.8-8.5-0.6
        c-1.1,2.2,1.2,4-1,4.1c-5.5,0.4-9.9,2.6-14.4,4.5l-0.9-0.2c0.5-2.1-1.4-2.7-1.9-4.1c4.4-1,6.3,3.2,10.2-0.5
        c-13.5-2.1-18.4-5.3-26.5-3.4c0.9-3.7,5-2,5.7-5.9c-3.3-0.3-5.6,1-9.8-0.9c-3.1,2.3-0.1,5.4-3,6.6c1.5-3.7-4.8-2.5-4.5-5.4
        c-1.9-0.6-3.8-1.1-4.4,0.4c-3.4-2.1,3.8-2.3,4-4.9c1.2,0,1.2,0.8,1,1.7c-0.2,0.9-0.4,1.8-0.6,2.8c2.1,0.2,2-3,2.6-5.2
        c-3.9-1.3-4.9,2.1-8.8,0.8c3-0.6,0.8-6.2-0.7-3.1c3.3,1.9-4.4,1.4-6.1,1.5c0.2-1.6,2.5-1.7,0.6-2.7c-1.6,1.7-4.5-4.8-8.5-0.5
        c1.8,1.2,7.4,0,6.5,2.9c-8.9-2-25.3-3.3-32.6-7.5c-0.1-2.1-1.1-2.7,0.9-4.1c-4.2-1.8-3,4.5-5.8,0.1c0.1,4-3.3,2.2-4,4.9
        c1.3,3.5,3.7-1,3.8,2.3c-3,2.3-4.5,0.3-6.1,1.5c3.2,0.7-1.3,3.6-3.3,2.2c0.4-1.9,1.3-2.9,2.3-3.8c-4.6-1.8-6.5,0.9-9.1,2.3
        c0.6-2.7-3.3-2.6-1.5-6.1c3,0.3,14.3,4.3,11.2,1c-5.2-0.9-8.6-1.9-3.5-3.7c-6.6-4.4-5.5,6.3-6.8-1.5c-2.7,0.7-6.9-1-8.8,1
        c1.5,0.6,1.8,2,2.1,3.4c-5.7-1.9-2.6,3.3-5,3.2c-2.4-0.4-3.2-1.8-3.5-3.7c2.2,3.4,2.8,0,5.7-0.2c0.9-3.7-1.7-3.3,1.2-5.5
        c-4.8-0.4-7.5-2.2-10.6-3.8c-0.9,1.6-2.4,2.4-4.7,1.9c-11-0.7-15.9-5-27.5-4.4c-0.9-2.6-5.5-2.7-7.6-4.5c-2.4,1.8-7,0.1-9,2.4
        c0.4-1.8-3.4-2.5-4.1-0.8c3.2,0.7-1.3,3.7-3.3,2.2c3.1-2.1,2.7-0.6,2.5-5.3c-6.8,0.7-12.5-2.6-18.8-5.3c-1,2.3,1.3,4-0.8,4.2
        c-1.4-0.3-1.8,0.8-3,0.8c2.3-9-10.1-0.5-9.8-7.7c5.2,1.2,9.9,1.9,12.8-0.3c-8,0.5-11.8-3.6-18.3-0.8c4.2-1.4,5.4,4.8,1.9,4.7
        c-0.2-5.1-5.2-2-9.5-1.9c-4.4-3.2-10.8-3.7-18.8-5c-6-1-14.3-1.1-15.2-1.4c-0.5-0.2,2.8-2.5,0.5-2.8c0,0-0.2,0.1-0.4,0.3
        c-0.2,0.2-0.6,0.5-0.9,0.8c-0.7,0.6-1.5,1.1-1.9,1.2c-1.5,0.1-6.9-2.7-7.9-2.9c-2.5-0.4-5.8,0.5-8.4-0.1c-8.4-1.8-16.7-5.7-25.3-7.3
        c-3.9-0.7-8.5,0.2-12.5-0.7c-6-1.3-11.9-2.5-17.4-3.5C89,5.8,83.9,5.1,79.3,5c-2.7-0.4-1.1-4.1-5-3.6c-0.5,1-0.5,2.8-1.8,2.6
        C70.9,1,63.8,2.2,61.3,0h-0.8C60.2,1,60,2.1,59,1.9c-2.8-0.7-5.7-1.4-8.7-1.9H19.9c0.2,0.3,0.5,0.6,0.7,0.9c4.1-1.4,9.8-1,11.9,3
        c1-1.9,2.6-3.1,4.6-3.7c0.3,1.8,1.3,3.1,3.7,3.4c0.2-0.1,0.4-0.2,0.6-0.3c0.7,0.6,1.8,1,3.1,0.3c-0.4-0.4-0.8-0.7-1.1-1
        c3.5-0.6,5.8,1.9,8.2,2.4c4.8,1,9.9-0.1,15.1,0.8c3.1,0.5,4.8,3.5,7.7,4c3.1,0.5,7.7-1.9,11.3-1.1c8.5,1.9,19.5,5.4,29.4,7.6
        c3.6-1.3,1.3-2.6,3.4-3.7c3.8,5.6,21.2,7,28.3,6.4c-0.1,1.6-2.4,1.8-0.5,2.8c4.8-1.8,18.2,0.8,20.1,5.1c0.8-5.5,2,0.8,4.6-2
        l14.9,2.8c1.9-0.1,3-1.5,5.7-0.3c1,0.5,1.4,1.5,0.8,3c5.4-2.5,9.8-0.6,13.3,4c-0.3-5.3,9.6-1.4,8.1,1.6c3.6,0.9,8.9,0.7,9,4
        c-0.1-0.1-0.2-0.2-0.3-0.3c-0.5,1-1.1,0.8-1.7,0.2c0.2-0.3,0.5-0.6,0.8-0.9c-7.8-0.3-9.7-0.7-14.4,1.5c0.6,0.4,1.2,0.8,1.8,1.1
        l-59.6-11.1c0-0.5-0.1-0.9-0.7-1.1c-0.1,0.4-0.4,0.7-0.7,0.8l-7.2-1.3c0.4-0.4,0.6-0.9,0.5-1.8c-3.3-1.2-3.1,1.3-4.9-1.5
        c-0.9,0.9-0.8,1.7-0.2,2.4l-7-1.3c0.6-0.7,1.5-1.2,3.2-1c0,1,0.6,1.8,2.1,2c0.9-4.3-3.2-2.7-6.2-1.9c0.8-3.1-4.1-1.7-4.9-1.5
        c2-2.5-8.2-3.3-3.7-1.1c2.1,0.7,3.9,1.6,5.2,2.8l-38.2-7.1c-1.2-1.6-4.9-4.5-1.8-1.2c0.1,0.1,0.2,0.5,0.3,0.9L0,2.8v539.4
        c4.1,0.6,8.3,1.2,12.5,2c-0.8,1.1-1.7,2.3-1.8,4.3c4.7-8.1,14.3-3.4,24.3-2.7l0.2-2.4c1.6,0.3,1,2.6,1.1,4.3c4.9,1,7.8,2.7,11.3-0.6
        c3.2,3.1,2.8,2.2,8.3,2c3.2,1.3,9.5,3.4,12.6,0.9c1.9,2.2,1.6,6.4,6.5,6.1c-9.4-8.2,10.1,1.8,6.1-5.1c2.8,2.8,10.2,1.7,15.3,2.5
        c1,4.8-4.5,1.8-7.3,2.2c3.3,4.7,9.8,1.8,13.8,3.8c3.8-0.8-3.8-2.6-5.4-3.2c0.4-2.6,4.7-0.6,4.6-3.8c1.4,0.1,0.9,1.8,1.2,2.9
        c2.2,0,5.3,1.1,5.7-1c1.6,0.5,4.8,6,6.9,1.9c1.4,0.1,0.9,1.8,1.2,2.9c1.2,5.6,1.9-9.6,4.4-2.4c2.7,0.1,1.4-4.5,4.6-3.8
        c-1.3-5.4-5.5,1.1-2.3-5.8c-7.8-1.6-19.5-1.4-25.3-1.9c-1.4-0.1-0.9-1.8-1.2-2.9c-9.3,0.4-15.6-4.1-19.7-1.5
        c-1.4-0.1-0.9-1.8-1.2-2.9c-3.5-0.1-4.6,2.3-7-0.5c1.4-2.2,12.2-5.1,14.2-0.4c0.8-3.2,6.3-0.9,6.1-5.1c4.5,2.6-4.8,4.1-0.6,6.9
        c2.9-0.2,3.1-3.5,6-3.7c14.2,3.3,26.4,5.5,37.8,4.3c1.4,3.5,7.3,3.3,7.8,7.6c2.2-1,2.4-4.3,3.4-6.7c3.5,0,4.2,2.5,5.2,4.6
        c-0.4,1.1-1.5,1.3-1.7,2.7c-3.3,3.2,1.7-2,4.3-1.1c4.4-0.7,2.4,4,6.8,3.3c2.3,0.7,2.8-0.9,3.1-2.6c1.1,0.8,2.1,1.6,4.1,1.7
        c-1.2,1.1-1.8,3-1.9,5.4c-8.5-0.5-10.1-3.8-19.5-2.9c0.9,1.4,2.4,2.3,4,3.1c-0.6,2.4-2.7,3-5.9,2.3c-1.5-1.4-0.2-2.6-0.8-7
        c-2.9,0.2-3.1,3.5-4.7,5.2c-1.1-0.3-1.3-1.5-2.7-1.6c0.4-1.6,1.5-0.7,1.4,0.1c4-1.9-2.5-4.1-5.5-1.8c-1.1,0.8-0.6,4-1.5,1.3
        c-1.3,2.1-4.6,1.7-4.7,5.2c3.6-3.4,11.8,3.1,11.5-1.9c1.7,0.7,2.7,2,2.4,4.4c4.4,1.5,4.5-1.8,7.3-2.2c4.4,2.7,10.5,4,16.5,5.5
        c-6.4,2.7-10.2-1.4-19.6-1.5c-3.5-0.4-2.5,1.5-0.1,1.4c13.4,3.6,21.8,4.7,34.8,5.5c2.6,0.4,4.6,1.3,5.4,3.2c4.1-1,7.5-1,11.3-0.5
        c1.6,0.2,4.3,3.2,6.7,3.3c1.9,0.2,4.6-1.2,5.7-0.9c1.2,0.3,3.4,3,3.9,3.1c2.5,0.5,3.1-1.4,5.7-0.9c13.5,2.5,27.7,4.3,40.2,7.6
        c4.8-1-1.3-2.9-1-4.3c3.3-4,8.4-2.4,11.6-3.2c-0.8,1.1-1.7,2.1-1.8,4c1.3-0.7,2.7-1.5,2.8,0.2c1.2-1.9,8.1-3.1,11.5-1.8
        c1.2,0.5,1,1.5,1.1,2.9c1.3-2.1,6.8,0.9,6-3.7l-11.2-1c-0.6-2-0.6-2.1-1.2-4.1c3.3-2.3,9.3-2.3,14.5-1.7c1.5,0.2,3.1,2.8,3.9,3.1
        c3.4,1.2,7.9-0.9,11.3-0.4c3.5,0.6,6.2,4.3,9.7,2.3c0.3,2.4-1.4,2.5-3.1,2.5c1.5,3.6,6.9-0.9,6.2-5c1.2,1.1,1.9,2.7,2.4,4.4
        c3.4,0.5,2.6-1.5,0.1-1.4c4.3-2.9,10.1,7.9,15.4,1.4c3.5,5.1,10.9,2.7,17.6,7.2c2.2,0.6,2.5-1.1,4.3-1c5.4,1.4,10.3,4.6,15.3,2.8
        c2.6,0.4,4.6,1.3,5.3,3.3c1.9,0.4,0-5.7,2.9-1.1c4.8-0.8,1.5-3,0.6-5.5c4.8-1,4.4,2.4,3.6,5.9c4.5,0.6,10.9-0.3,9.4,5.1
        c2.7-4.9,10.9-0.9,15.5,0.1c-2.8,3.6-3.8,1-8.5,0.6c-4.7,1.5,5.4,1.4,5.3,3.3l10.1,1c0,0-2.1-3.3-5.3-3.2c3.2-3,11.7,0.6,17,0.2
        c2.5,3.1,7.6,12.6,10.9,4c1.2,1.2,1.8,2.8,2.3,4.6c5.7-2.1,7.9,3.5,15.4,1.5c1.2,0.8,2.4,1.5,2.5,3.2c-0.8,2.1-1.7-0.7-4.2-0.4
        c1.4,4.1,6.5,5.1,12.1,5.6c0.2-6.1-3.8-4-6.7-3.6c0.9-6,11.3,0.2,14.5-2.9c4.1,0,3.9,3.7,8.1,3.7c-0.1-1.2-1.2-1.6-1.1-2.9l4.2,0.4
        c1.6,0.3,0.6,1.4-0.1,1.3c2.2,3.4,10.2,2,12.1,5.6c6.9-3.3,8.9,3.6,16.3,6.1c-2.3-1.2-1.8-4.6-3.5-6.2c6.5-2,10.8,1.2,18,3.3
        c-0.3,1.2-0.9,2.3-1.3,3.4c1-0.2,2.6,0.4,2.5-1.3c0.9,0.1,1.9,0.2,2.8,0.3c-0.2,1.4-0.3,2.9-0.5,4.3l-3.9-0.4
        c-0.5-0.5-1-0.9-1.4-1.4c-0.3,1.2-0.3,2.5,0.8,3.8c3.3,0.9,3.3-2.3,7.2-0.7c-2.6-3.8,3.9-6,6.6-8c0.2,2.5-1.4,2.6-3.1,2.6
        c5.3,4.2,12.9,1.5,16.8,1.8c2,0.9-0.8,1.8-0.5,4.3c5.1-3.1,10.2,2.3,16.8,1.8c2.3-0.1,4-4.2,7.3-2.1c3.9,2.5,8.9,4.8,10.6,5.6
        c1.8-0.4,3.1-1.5,3.3-4c4.7-1.3,0.5,4.8,5.2,3.5c0.7-2.3-1-2.6-0.9-4.5c1.8,0.7,1.9,2.8,3.8,3.3c-0.6,2.5-2.8,3-5.9,2.3
        c4.6,4.3,13.9,0.5,19.7,0.8c-1.1,1.4-3.4,1.1-3.3,4c7.6,0.1,13.6-2.1,21.1,1c-0.6-3.4-3.7-4.8-6.3-6.6c0.8-1.2,1.5-2.4,3.2-2.6
        c1.3-4.5-6.9-1.3-4.9-6.4c-1.4-0.1-1.3,1.6-1.8,2.7c-4.4-3.7-5.7-1.6-12.2-4.3c7.3-0.4-5.9-6-11.9-7.2c-1.6-9,14.1-1.7,9.6-9.1
        c2.8,1.1,2.2,4.6,5.4,2.1c0.3,3.2-2.3,2.5-4.6,2.4c2.4,4.5,7.9,2.8,11,2.7c4,1.7-3.5,1.8-2.1,5.6c4.8,4.3,11.7-0.8,17.1-1
        c2.6,0.9-0.7,6.6,5,5c-1.7,3.4-9.7-1.5-11.5,1.6c1.2,2.9,0.8,7.1,6.3,6.6c-1.2,1.2-2.8,1.9-4.6,2.4c1.6,1.4,7.8,5.6,9.6,2.6
        c5.7,3,9.2,7.7,16.2,6.3c0.4-2,0.8-4-0.8-4.5c3.3-2.5,15-1.9,25.1,3c-1.5,1.5-3.1-0.5-6,2.2c-5.2-1.9-12.3-4.4-16.6-3.4
        c-2.3,4.1,2.7,3.5,5,5c2.6,1.6,4.7,4.5,8.2,2.4c2,0.9-0.8,1.8-0.6,4.3c3.6-1.7,3-2.7,7.2-0.6c-1.7-2.8-0.9-3.4,3.2-2.5
        c1-3.4-6.5,0-3.6-4.8c2.3-1.1,5.3,0.8,8.2,2.4c1.4,0.8,2,4.3,3.6,4.8c1,0.3,3.7-2.1,5.8-0.8c0.2,3.2-2.4,2.5-4.6,2.4
        c-0.6,3.1,4.9,1.3,6.8,2.3c-1.4-1.9-0.1-3.7,0.2-1.4c5.2-1.9-3.7-2.8-0.4-7.4c3.2,0.9,5.5,2.4,8,3.9c-0.5,1.6-1.5,0.6-1.4-0.2
        c-5.1,3.7,9.3,2.8,10.6,5.7c12-0.7,25.4,5.7,34.8,4.4c2.8,0.4,1.2,4.2,5.2,3.6c6.4-2.4,11.8,1.1,16.7,2.2c1.2-3.1-2.4-2.4-2.2-4.7
        c-0.2-2.9,6.6-1.3,4,2c6.7-3.3,15.5,1.1,21.3-0.1c-2.5,6.7-10.4-4.3-11.9,4.3c6.3-4.1,10.8,5.4,15.3,2c1.4,5.5,7.7,4.3,10.5,5.8
        c-1.2,3.6-9.5-2.8-7.8,4.8c1.4,0.1,1.3-1.6,1.8-2.7c6.9-0.7,5.2,5.3,12.1,4.6c-0.8-1.3-1.6-4.2,0.6-4.3c3,1.2-2,2,0.8,4.5
        c3.2-0.7,5.9-2.3,10.1-1.5c-2.5-4.7-6.4-1-9.3-4.2c2.9-3.5,6-2.3,2.4-7c3.9-0.5,2.4,3.2,5.1,3.6c4.5-0.9-3.8-1.9-2.2-4.7
        c2,2.7,8,2.3,12.3,3.2c-3.5-3.5-13.3-7.4-16.5-3.7c3.2-2.9-0.1-1.6-0.8-4.5c0.5-1.6,1.5-0.6,1.4,0.2c4.1,0.4-4.1-9.3-4-2
        c-2.2-2.9-4.3-6-2.5-12.1c4.3,0.4,6.5,5,7.4-1.9c2,0.1,4,0.3,4,2c-2.5-0.6-2.6,3.4-7.6,3.4c4.6,3.6,16.1,2.3,22.8,9
        c3.3-0.4-1.4-2.5,2-4.1c1.8,0.7,0.6,3.8,0.5,5.9c6.2,2.8,5.4-4.1,11.6-1.3c2-1.6,0.9-2.2,0.6-4.3c5.3-0.3,7.2,4.6,11.1,1.6
        c1.1,3.2-3.2,6.5-3.2,2.5c-2.6,4.6,8.2,4,11.9,6.1c1.1-4.6-0.6-7.1-4.5-8c1.9-3.7,4.9,1.9,5.1,3.7c2.1-1.5,2.3-0.2,4.2,0.6
        c0.1,1.3-0.8,1.3-1.6,1.2c1.8,4.1,9.6-2.2,9.3,4.3c11.8-0.8,13.8,0.1,21.8-2.7c1.1,0.4,1.2,1.7,2.6,1.9c-1.2,1.2-2.9,1.8-4.6,2.2
        c-0.2,3.4,5.2,0.6,5.8-0.6c1.3,1.1,0.9,3.5,3.7,3.5c1.9-1.9,2.2-3.4,0.9-5.7c4.4-0.8,2,3.6,1.9,6.2c4-3.3,9.9,5,13-1
        c0.7,0.5,0.4,6.5,2.8,0.4c2.8,0.4,5.6,0.8,8.3,1.3c0.6,4.9-11.8,1.3-2.1,4.1c2.6,1-2.9,3.7,3.7,3.5c-1.9,5.1-11.4,1.1-12.5-1.9
        c-4.8-0.3-8.3,1.1-14.1-0.7c-1.4,1-1.5,3.8-3.5,3.9c-2-0.9,0.8-1.7,0.7-4.3c-4.8,0.4-18.8-5.5-15.7,0.6c-4.5-2,0.3-3.2-1.9-6.1
        c-3.6-0.4-2.4,5.9-7.6,3.3c1.2-1.2,2.9-1.8,4.6-2.3c-2-2.8-3.7-0.3-8.1-2.7c-3.1,2.4-0.2,2.9,0.4,5.5c0,0.1,0.1,0.3,0.1,0.5
        c0-0.2,0-0.3-0.1-0.5c-1-3.7-9.6-1.5-8.8-6.7c-3.3,2.4-1.7,2.7-4.2-0.6c-2.7-3.7,2,3.9,0.3,7.4c-4.4,0.5-0.9-5.2-6.5-3.9
        c1.8,4.9,13,8,17.4,6.9c3,1.3-2,2,0.7,4.5c-2.8,1-4-5-5.5-0.8c-1.9,4.1,11.7,3.4,11.5-1.2c2.3,2,4.1,4.4,6,6.8
        c2.2,0.2,5.2,1.5,5.8-0.6c4.3,4.8,16.8,2,22.9,7.8c1.1-3-1.5-3-3.7-3.5c2-4.9,7.7-5.4,11.8-2.6c-0.6,1.7-3,1-2.8-0.4
        c-2.1,0.3-0.1,1.3-0.5,2.9c-0.8,1.9-2.5-0.3-2.5-1.9c-5.2,1.7,3.2,7.8,3.9,2.1c1.4,3.1,7.8,5.7,9.9,0.1c-1.3,0.4-2.7,0.9-3.2,2.4
        c-5-1.9,0.7-5-1.6-7.6c3.2,0.9,5.5,4,7.2-0.4c1.3,1.3,5.5,6.6,8.1,2.7c2.3,3.8,2.2,2.2,7.8,4.2c0-5.8,8.4,0.2,8.1-6.1
        c-3.4-0.8-5.9-0.2-7.4,1.8c-1.5-0.5-0.5-1.6,0.2-1.4c-0.1-3.3-7.2-2.3-8.3-1.3c-1.5-2.9-3.4-3.8-5.3-2.3c-2.3-0.8,2.6-4.7-3.5-5
        c3.3-2.6,8.1,5.9,13.9,2.2c0.4-7.7-6.1-2.8-5.5-9.7c0.2,5.4,5.8-0.5,7.9,4.2c1.6,0,1-1.3,0.2-1.4c2.4-0.1,7,1.2,9.2,4.4
        c-1,3.6-2.1-2.3-5.6-0.9c-0.1,3,6.1,1.3,4.8,5.2c2-1.9,6-0.8,6.5-4.8c2.6,1,0.2,5.7,4.8,5.2c0.9-2.4-0.6-2.9-2.3-3.3
        c1.6-3.1,7.9,0.7,11.3,0.4c1.1,6.4-7.7-1.6-6.5,4.8c2.9,1.4,5.1-3.3,3.9,2.1c1.2-0.6,2.5-1.1,4.4-0.8c0.8-2.2-0.9-2.7-0.7-4.5
        c1.5,2.1,4.8,2.7,6.9,1.1c3.6,1.9-4.8,2.5-1,5.7c4,0.4,7,1.5,7.8,4.2c-0.8,1.9-3.7,0.6-5.8,0.5c2.8,3.9-2.5,2.3-2.6,6.9
        c2.3,1.3,6.5-3.2,6.9,1.1c3,0.1-1.4-4.2,3.5-3.8c0.3,2,0.2,3.8,1.5,7.6c2.5-1.1,5.8-0.4,3.5-3.8c1.4,1.5,3.4,2.5,6.7,2.6
        c2.3,0.8-2.7,4.7,3.4,5c-0.1-1.2-1.1-1.7-0.9-3.1c6.1-3.1,10.5,0.6,18.2,1.6c0.2-0.9,0.3-1.9,0.5-2.8c4,2.9,6,1.6,11,1.9
        c4.9,1,4.1,6.2,10.3,6.2c-0.1-2-2-2.7-2-4.8c0.8,0.6,4.7,4.5,5.5,1c1.9,1-0.9,1.7-0.8,4.3c4.1,0.6,4-4.9,9.1-2.8
        c-0.3,1.4-1.9,0.8-3,0.9c2.7,6.7,17.7,4.7,25.6,7.6c0,1.3-0.8,1.2-1.6,1.2c-0.6,3.4,3.9,0.2,3.3-2.3c4.3,2.4,10.3,6.6,12.7,0.9
        c3.4,6.9,11.8,3.1,17,7.7c6.7-1.6,9.9,3,15.7,0.1c-0.7-1.6-4.1-1.1-6.6-2.8c-0.3-0.2-0.8-1-1.3-1.8c-0.2-0.4-0.5-0.8-0.6-1.1
        c-0.2-0.3-0.2-0.5-0.2-0.5c-2.2-0.6-10.9,6.3-9.6-1.9c-2.5,3.5-6.3,2.1-7.7,3c-2-0.3,0.1-1.8,0.3-1.4c-1.3-2.9-8-6.5-9.6-1.8
        c2.2-5.9-13.5-1.9-11-9.4c-1.2,4.1-12.6-2.6-9.6,5.6c-3.1,0.1-2.2-2.5-1.9-4.8c-3.7-1.9-4,1.4-7.2,0.2c-1.5-0.8-3-3.1-0.8-3.1
        c0.2-3-6.1-1.4-4.7-5.3c-4.2,0.1,1.7,2.6,0.6,4.5c-1,3.3-6.3-0.2-5.3-2.4c-2.1,0.3-0.1,1.3-0.5,2.9c-7,0.2-9.1-3-14.1-1
        c-3.2-0.6,1.2-3.8,3.3-2.4c-0.5-3.5-6.6-0.4-7.4,1.6c-6.4-2.1-13.6-3.1-21.6-6.7c0,2.4-1.7,2.2-2.1,4.1c-3.3-0.1-0.8-4.4-3.4-5
        c0.7-2.6,4.8-0.1,6-1.9c5.6,3,9.5-0.3,13.6,3.8c2.1-0.3,0.1-1.3,0.5-2.9c4.6,3,12.6-3.6,12.2,3.6c3.4,0.9,3.9-2.6,6.3-3.3
        c1.9,1-0.9,1.7-0.8,4.3c5.5,2.7,6.5-1.2,10.2-1.1c-0.1-4.4-3.2,0.8-5.5-1c1.5-2.7,4.4-3.1,7.7-3c2.8,2.6,8.7,2.9,7,8.6
        c3.1,1,5.3,0.7,7.2-0.2c4.9,3.8,4.8-2,9.7,1.8c2.4-4.2,1.4-3.6,0-7.4c1.7,2.3,12.2,1.1,9.4,3.2c0,3.3,7.1,2.5,8.3,1.6
        c-2.9,9.6-11.3,0.1-14.9,3.1c-0.9,2.5,0.7,3.2,1.1,1.7c3.2-0.1,2.1,1.6,5.2,2.5c-1-0.3,4,0.5,4.4,0.7c1-0.3,3.2-2.2,3.1-2.3
        c1.6,0.4,1.4,3,2.2,3.4c1.3,0.7,9.5,0.4,13.5,4.1c1-2.9,3.3,2.8,4.7-2c4.5,0.1,2.6,5.6,6.6,2.8l0.9-4.3c-5.8-2.7-7.3,1.1-11.6,0.7
        c6.1-5.4,16.1-1.8,23.2-1.3c3.9,0.8-4.1,3.2,2.2,3.4c-1.6,5.5-4.5-2.5-5,3.4c2.9,0.1,4.3,1.1,3.5,3.7c2.2-4.6,12.6-3.7,11.3,0.8
        c6.7-4.3,12.3,4.1,21.5,0c2.3,1.7,4.5,3.4,7.6,4.6c-0.6-6.2,3.2,0.5,4.1,0.9c1.7,0.7,4.7-0.4,4.4-0.5c2.9,1.5,2.2,5.1,4.9,4
        c0.9-1.8,3.7-0.4,5.8-0.2c0.5-1.1-0.2-1.5-1.1-1.7c0.2-0.9,0.4-1.9,0.6-2.8c-1.3-0.3-1.4,1.5-2,2.5c-2.1-1.8-3-5.4-6.5-2.9
        c1.1-1.1,4.4-4.5-0.4-4.5c2.8-2.8,3.9-1.4,7.2,0.1c0,1.3-0.8,1.2-1.7,1.1c1.3,1.1,6.2,2.9,12,4.1c1.3,0.3,0.7,2,0.7,3.1
        c3.2-2.9,10.5,7.1,12.7,1.4c1.5,1.8,4.7,2.5,6.2,4.4c5.2-2.2,8.8,3.3,12.7,1.5c1.5,0.6,0.4,1.6-0.3,1.4c3.6,5.5,7.2-1.2,4.1-5
        c4.5,0.4,3,4.5,4.1,7c9.4,4.6,12,1.2,23.2,5.7c0.4-3.4,0.4-7.5-3-5.3c1.5-2.7,3.8-4.1,6.9-4.3c2.8,1.1-3,3.3,1.7,4.9
        c-1.1,1.9-1.6-1-4.1-1c-2.6,4.4,3.7,3.3,6.1,4.5c-0.9-1.4,0.2-4.1-1.6-4.9c5.6-0.9,9.5,1.1,13.1,3.1c3.6,2,7,4,11.4,3.3
        c-3.2,4-7.2-3-7.9,2.4c-3.8-3.2-6.8-6.9-13.9-8.1c-0.3,1.4,1.4,1.5,2.4,2.1c1.5,0.7,0.4,1.6-0.4,1.4c4.8,3.2,13.8,5.1,22.4,8.9
        c1.6-2.9-2.1-2.7-1.6-4.9c0.8-2.6,5.1,2.3,4.7,4.3c2.3-0.9,0-2.5,3.9-3.5c1.5,1.9-1.2,2.2,1.6,4.9c3,1.3,2.6-3.6,5.2-3.1
        c1.2,0.8,0.2,4.1-1.2,4.2c0.3,1.9,5.4,0.5,7.7,3.7c0.1-1.2-1-1.8-0.6-3.2c0.5,5.6,7.8,0.1,6.9,6.5c4.3-0.7,9.5-0.2,13.7,1.1h11.8
        v-0.2c-1.1-0.1-2.3-0.2-3.6-0.6c-3.9-4.2-0.8-5.2-2.9-10c4.4,0.9,4.1,4.4,6.5,6.4v-8.6c-1.4-0.8-2.7-1.5-3.9-1.7
        C1362,754.7,1363.1,755.3,1362.6,756.7z M1336.3,250.7c0,0.1,0,0.2,0,0.3l-3-0.5C1334.1,250.2,1335,250.1,1336.3,250.7z
         M1329.5,249.2c0.9,0.4,2.1,0.8,3.5,1.3l-4.4-0.8C1328.9,249.3,1329.1,249.1,1329.5,249.2z M825.9,118.7
        C830.1,115.4,834.2,122.7,825.9,118.7L825.9,118.7z M844,122.3c0.7-1.5,2.8-0.4,4.5-0.2C847.7,123.7,845.6,122.6,844,122.3z
         M670.2,116.5c-1.8-1.3-2.9-2.9-4.7-4.2C665.3,110.3,672.4,113.5,670.2,116.5z M103.2,11.7C106.9,7.9,111.6,14.7,103.2,11.7
        L103.2,11.7z M214,34.7c1.8-2,4.3-2.5,5.7-0.3C219,36.2,216.1,34.9,214,34.7z M158.1,544.6C148.9,545.6,155.4,540,158.1,544.6
        L158.1,544.6z M161,559.4C157.2,554.2,166.6,555.4,161,559.4L161,559.4z M302.4,53.2c-0.9,3.5-5.1,1.7-8.8,1
        C295.7,52.5,298.9,52.6,302.4,53.2z M290.9,53.6c0,0.4,0.1,0.7,0.2,1c-0.3-0.1-0.6-0.1-0.8-0.2C290.5,54.2,290.7,53.9,290.9,53.6z
         M238.3,46.1c0.5,0.5,1,1,1.5,1c0,0.1-0.1,0.1-0.1,0.2l-3-0.6c-0.1-0.2-0.2-0.4-0.4-0.6C237,46.4,237.7,46.3,238.3,46.1z M245.9,559
        c-4.7-0.2-7.7,2.3-9.7-2.3C241.1,558.4,245.1,553.3,245.9,559z M234.9,44.1c0,0.2,0.1,0.4,0.1,0.6c-0.1,0-0.2-0.1-0.3-0.1
        C234.7,44.4,234.8,44.3,234.9,44.1z M226.2,44.8l-5.8-1.1C222.4,43.7,224.4,44.1,226.2,44.8z M218.4,41.4c-0.1,0.7-0.3,1.3-0.4,2
        l-4.3-0.8C215.1,42.4,216.6,42,218.4,41.4z M216.7,553.7c-0.2,1.5-2.6,1.6-2.8-0.2C214.4,551.7,215.5,552.7,216.7,553.7z M185,559.8
        c1.4,0.1,1.8-1,2.9-1.2c-0.2,2,0.4,3.2,1,4.4c-1.4-0.1-2.8-0.2-4.2-0.3C184.8,561.8,184.9,560.8,185,559.8z M184.3,567.1
        c9.1,1,12.9-2.8,18,4.4C195.5,570.7,187.1,571.3,184.3,567.1z M209.3,572c0-1.7-2.2-3.5,0.4-4.3C209.8,569.4,211.9,571.1,209.3,572z
         M213.1,562.1c-5.6-0.8-6.3-0.1-3.1,2.7c-2.6-0.5-3.3,1.4-5.8,1c1.1-3.6,2.9-5.4-2.3-6c-1.2,3.1,3.5,4.9-1.8,4.2
        c0.1-2.5,0.7-4.5,1.9-5.7c-6.2-0.2-11.9,2.2-15.3-2.7c2.6-0.5-0.5-1.6-1.1-3c3.6,0.1,7.4,6.7,9.6,3.7c-2.2-2.9-6.7-4-9.3-6.6
        c6.1-1.1,9.5,2.6,14.9,7C205.8,557.8,214.6,555.5,213.1,562.1z M215.5,566.7c-0.3-0.2-1,0-1.4-0.1c-1.7-0.6-0.3-2.5,1.5-1.3
        C216.6,565.8,217.5,567.9,215.5,566.7z M215.8,563.8c0-5.7,7.6-3.1,2.1-7.1c4.8,1.7,5-1.8,3.2-4.1c5.2,0.6,3.8,1.9,8.4,0.7
        c0.8,6.3-7.1,2-7.7,6.6c2.5,0.1,4.3,0.7,5.5,1.9c11.3-4.3,18.6,5.8,26.7,2.2c-0.7,8.9-10.5,1.3-11.9,6.3c0.8,0.1,1.5,1.3-0.1,1.4
        c-2.4-2.9-16.7-2.3-10-6.2c-3.3,1.5-5.1-2.8-7.9,0.4c3.2-0.1,5.2,0.8,5.3,3.3c-5.5-1.3-4.1-1.1-9.8-0.8c-0.1-2.8,1.9-3,2-5.7
        C218.4,565.1,218.9,561.5,215.8,563.8z M227.3,576.4c-1.2-2-3.5-3.1-3.7-6.1C226.8,569.9,231,575.2,227.3,576.4z M246.8,579.5
        c-3.8-1.7-14.1-2.3-16.6-4.3c0.9,0,1.7-0.1,1.5-1.3c-4.1,1.6-4.3-6.1-2.7-1.7c3.9-0.7,2.6,3.1,5.3,3.4c3.6-5.2,6.4,1.3,11.1,2.4
        c0.2-2.5,1.4-3.6,3.2-4.1C251.3,577,248.7,577.7,246.8,579.5z M255.1,567.1c3.5,2.3-3.3,5.4-3.1,2.6c2-0.2,1.9-3,3.2-4.1
        c4.3,0.3,7,1.9,8,5C257.8,571.8,259.6,566.7,255.1,567.1z M262.4,51.6l-2.7-0.5c0.7-1.3,1.8-1.7,3.1-1.8
        C262.3,50.6,262.2,51.3,262.4,51.6z M263.3,51.7l-0.4-0.1c0.4-0.1,1-0.5,1.6-0.8C264.2,51.2,263.8,51.5,263.3,51.7z M266.3,553.5
        c-1.1-1.1,2.7-1.1,2.9-1.2C273.5,550.5,267.6,554.7,266.3,553.5z M266.7,549.1C263.8,544,271.1,553,266.7,549.1L266.7,549.1z
         M271.1,577.2c-0.1-2.1-2.1-2.6-2.4-4.6C271.7,571.6,274.6,576.9,271.1,577.2z M270.8,47.8c-2.8,0.4-2.9,4-3.8-2.3
        c-0.9,0.4-0.9,1.8-1.4,3.2c-2.4-0.4-4.8-0.4-7,0.5c-0.1-1.3-1.9-1.6-3.5-1.4c-1.1-1.4-2.3-2.7-5-3c-3.7-3,6.1,0,8.4,0.3
        c-6.5,3.8,2,1.8,5.4,1.1c-0.7-2.3-4-2.9-6.2-4.2c10.1-1.3,14.6,5,23.4,3.5c-0.5,1.1-1.7,1.1-1.9,2.5c1.3-0.3,2.7-0.7,3.3-2.2
        c0.2,2-0.1,3.8,6.2,4.2c-1.6,4.7-7.4-0.3-12.5-1.2C276.8,49.1,270.5,47.9,270.8,47.8z M285,55.5c0,0.1-0.1,0.2-0.1,0.3l-1-0.2
        c0,0,0,0,0,0C284.3,55.5,284.6,55.5,285,55.5z M284.7,568.1c0-1.2-4.2-4.8-4.1-1.8c0.8,0.1,1.5,1.3-0.1,1.4
        c-0.1-2.1-2.1-2.6-2.4-4.6C282.6,562.1,287.8,567.1,284.7,568.1z M288.6,556.8c-0.5,1.6-2.7,3.3-3,1.2c1.1-0.2,1.8-1.1,1.7-2.8
        c3.2-0.1,5.3,0.8,5.3,3.4C290.8,558.5,289.7,557.7,288.6,556.8z M292.8,557.2c0.3-1.6-1.8-2.4,0.3-2.9
        C294.6,554.5,294.7,557,292.8,557.2z M294.1,558.8c1.6,0.3,2.4-1.9,2.8,0.2C296.8,560.5,294.3,560.6,294.1,558.8z M304.6,58.8
        c-0.1,0-0.2,0-0.3,0c0.2,0.1,0.4,0.3,0.6,0.4c0.1,0.1,0.2,0.2,0.2,0.3l-3.1-0.6c-1.4-0.8-3-1.6-4.6-2.2
        C300.1,56.9,302.8,57.3,304.6,58.8z M300,556.4c0.1,2.1,2.1,2.6,2.4,4.6C299.4,562,296.5,556.7,300,556.4z M307,571.5
        C297.8,572.4,304.3,566.9,307,571.5L307,571.5z M314.1,59.2c0.2-0.2,0.6-0.3,1.3-0.3c1.5-0.2,2.2,0.2,2.4,0.9
        C316.5,59.6,315.3,59.4,314.1,59.2z M326,566c0.3-2-0.3-3.2-1-4.5c1.9,0.2,3.7,0.3,5.6,0.5C331.2,566,327.4,564.5,326,566z
         M335,60.4C329.7,62.7,339.3,56.5,335,60.4L335,60.4z M381.4,63.8c-1,1.6-2.4,2.4-4.7,1.8C377.7,64,379.1,63.1,381.4,63.8z
         M338.6,65.7C338.6,65.7,338.7,65.7,338.6,65.7C338.7,65.7,338.7,65.7,338.6,65.7L338.6,65.7z M355.1,571.5c4.5-0.3-7.2-1.8-2.4-4.6
        c0,2.2,3.3,1.6,5.5,2c-0.3,2,0.3,3.2,1,4.5C357.6,573,355.2,573.2,355.1,571.5z M375.7,69.7c-5.2,0.1-6.4-0.1-9.5-2.2
        c-2.5,0.5-5.6,0.1-8.8-0.5c0-1-0.2-1.8-1.1-2.3c-0.3,0.8-0.8,1.4-1.4,1.7c-3.1-0.7-6.3-1.6-9.3-2.2c-0.3,0.7-0.2,1,0.2,1.3
        c-0.5-0.1-0.9-0.2-1.4-0.3c-0.8-1.3-1.9-2.9-4.2-2.2c5.6-1.6,2-4.2-1.5-6.1c0.9-3.9,3,4.1,3.3-2.1c6.5,4.4,9.5,3.7,17,6.8
        c-0.5,1.1-1.7,1.1-2,2.4c6.8,1.2,5.9,0.7,11.2,1.1c2.6-0.3,4.7,5.3,6.8,1.6c1,0.5,1,1.7,2.4,2l4.1,0.9
        C380.6,71.3,377.8,69.9,375.7,69.7z M384.3,577.1c0-1.7,0.7-4.1-0.9-4.5c-0.6,2.2-2.3,0-2.7-1.7c2.4,0.3,4.4,0.1,5.8-0.9
        c-0.3,2.5,0.7,3.8,2.3,4.6c0.2-0.9,0.4-1.7,1.6-1.3C390.4,577.2,387,576.8,384.3,577.1z M386,587.5c0.4-1.7,0.2-4.1,1.9-4.2
        c0.1,1.8,1.5,1,2.8,0.3C391.2,587.5,387.5,586.1,386,587.5z M404.6,75C410.1,71.2,411.2,79.6,404.6,75L404.6,75z M405.5,577.7
        c0.4,2.4-7.9,1.6-7-0.7c4.8-0.3,10.3-2.5,10.8,5.4C403.1,582.5,407.9,578.4,405.5,577.7z M415.1,79c0,0.4,0.1,0.7,0.2,1l-1.2-0.2
        C414.1,79.3,414.3,79,415.1,79z M410.9,579.7c3.5-1.8,6,1.1,7.2-0.8c-1.8-0.3-1.7-2-4.1-1.9c1.2-4.4-2.6-6.2,2.1-7l0.1,0
        c-1.8,7,4.2,6.6,7.2,12.3C420.9,584,413.7,581.7,410.9,579.7z M426,82c0.3-0.8,0.8-1.4,1.7-1.4c-0.3,0.6-0.5,1.2-0.5,1.6L426,82z
         M427.5,584.2c4.1-0.4-1.9-2.5-0.9-4.5c1.6-0.4,3.6-1.4,1.4,0.1c0.1,2.6,2.1,3.5,5.3,3.4C435,587.5,426.7,586.7,427.5,584.2z
         M443.7,578.6c-0.2,1.4,0.9,1.8,1.1,3c-0.9,0.3-5.8,3.1-5.6-0.6c1.1-0.2,1.8-1,1.7-2.7L443.7,578.6z M438.5,83.1
        C433.6,84.6,447.1,78.8,438.5,83.1L438.5,83.1z M434.6,583.4c2.5-2.1,5.7,1.9,11.2,1.1C444.2,590.4,435.9,587.7,434.6,583.4z
         M471.5,89.7c-0.1-0.1-0.3,0.2-0.6,0.5l-4.6-0.9C467.1,88.5,470.9,89.4,471.5,89.7z M446.6,603.5
        C454.4,601.3,450.5,609.1,446.6,603.5L446.6,603.5z M451,589.4C448.1,584.2,455.3,593.4,451,589.4L451,589.4z M450.1,584.9
        c-0.3-0.9-2.9-5.9,0.6-5.8c0.1,1.8,1.5,1,2.8,0.3C453.5,582.8,452.6,584.8,450.1,584.9z M455,87.3c-0.1-0.1-0.1-0.1-0.2-0.2
        c0,0.1,0,0.1,0,0.2l-5.4-1c0-0.2,0-0.3,0-0.5c1.9-1.2,7,2,4.7-1.8c-1,2.1-1.9-0.6-0.7-3.1c0.9,3.7,10.9,1.9,11.6,5.7
        c-1.5,0.4-3.4,1.1-5.3,1.5L455,87.3z M456.9,587.1c3.2,0.8,2-4,4.7-3.9c-2.8,5.4,3.3,3.3,3.6,6.2c-0.8-0.1-1.7,1-0.2,1.4
        c0.5-1.6,1.8-2.1,3.1-2.6c-0.3,1.5,5.7,3.5,0.9,4.5c-0.2-1.2-1-1.8-2.6-1.7c-0.4,1.1-0.4,2.8-1.7,2.7
        C463.5,590.3,458.1,590.5,456.9,587.1z M464.8,605.4c1.8-0.8,2.4,3.7,5.6,0.6C469.3,611.7,461.6,606.9,464.8,605.4z M473.7,601.9
        c-1.5,3.7-13.2-1.9-8.1-3.7C465.2,603.3,478.2,600.2,473.7,601.9z M475.5,90.7c2.4-1.7,5.5-2.3,9.2-2.1c-0.4,1.3-1.9,0.7-3.1,0.7
        c0.6,1.4,1.8,2.5,3.1,3.5l-5.2-1C478.4,91.1,477.1,90.5,475.5,90.7z M478.5,596.5c-0.1-2.9,2.2-2.7,3.3-4c-3.9-2,2.3-1.3,1.2,1.6
        C480.8,594,481.5,597.7,478.5,596.5z M482.1,602.7c6-1.1,11.4-0.6,12.1,5.6C490.2,606.5,483,607.2,482.1,602.7z M499.9,595.8
        c-3.5-0.2-4.6,2.3-7-0.7c-0.3,4.1-3.9,3.8-7.5,3.6c2.3-3.1-3-5.8,0.7-5.8c0.8,4.9,3-1.3,4.4-1c0.4,5,5.4-5.9,5.8-0.9
        C490,593.8,500.4,593.4,499.9,595.8z M508.3,608.4C505.5,603.2,512.6,612.3,508.3,608.4L508.3,608.4z M510.2,592.5
        C503.4,588.3,515.4,589.3,510.2,592.5L510.2,592.5z M511.5,91c2.7-0.1,12.7-1,11.5,5.8c-3.5-0.8-1.4-1.4-0.7-3.1
        C519.1,93.8,513.5,94.4,511.5,91z M521.6,603.9c-1.6-0.3-2.4,1.9-2.8-0.3C519,602.2,521.5,602.1,521.6,603.9z M525,611.6
        c-1.7,0.1-3.9-0.7-4.4,1c1,0.4,2.8,0.3,2.6,1.7c-2.6-0.9-6-1.2-5.1-4.9C520.2,609.6,529.6,610,525,611.6z M523.5,599.7
        c-0.6,3.5-4.7-0.2-4-1.9c5-0.3,7.1,2,7.9,5.2C524.6,603.2,524.8,600.8,523.5,599.7z M533,615.3C524.7,611.3,530.6,607.4,533,615.3
        L533,615.3z M528.1,99.6c-1.4-0.7-0.2-3-2-3.4c1.2-3.1,3.2,2.5,5.1,2.7c1.1,0.6,1.4-0.2,1.7-1C536.1,102.2,527.9,98.1,528.1,99.6z
         M534.7,600.9c0.4-0.4,2.3,0.6,2.8,0.3c0.2-0.1,1.3-2.1,1.4,0.2C539,604.6,532.2,603.6,534.7,600.9z M540.3,102.7
        c-0.1,0.2-0.2,0.3-0.3,0.4l-1-0.2c-0.3-0.4-0.7-0.8-1.4-0.9C538.4,100.4,540.7,101.3,540.3,102.7z M542.4,100.3
        c6.6,3.1,8.8,3,13.3-1c2,6.3,2.5,1.6,4.4-0.3c1.4,0.4,2.7,0.7,4.1,1.1c-0.5,1.7-2.2,1.4-2.4,3.7c2.3,1,7.8-2.1,8.1,2.1
        c-5.1,0.7-3.7,0.7-2.5,2.3l-21.7-4c0,0,0-0.1,0-0.1C546.3,101.8,542.2,102.3,542.4,100.3z M549.1,622.9
        C541.9,626.2,546.3,617.8,549.1,622.9L549.1,622.9z M551.2,617.3c0.2,0-0.5-0.8-1.1-1.6c-0.3-0.4-0.7-0.8-0.9-1.1
        c-0.3-0.3-0.4-0.5-0.4-0.5c0.4-0.6,3.6,0.9,4.2,0.4C560.5,609.3,551.4,617.3,551.2,617.3z M556.3,610.5c-0.1-5.4-8.6,1.2-9.3-5.4
        c5.6-2.3,9.8,1.6,15.2,3.1c0.1-1.5,1.1-3.6,1.6-1.3C562.5,609.7,559.6,610.4,556.3,610.5z M569.6,629.5c0.2-1.4-0.9-1.8-1-3
        c1.4,0.2,1.8-1,3-1.1c-0.2,1.5,0.9,1.8,1,3C571.1,628.2,570.7,629.3,569.6,629.5z M567.4,611.7c0.3-2-0.3-3.2-0.9-4.5
        c1.9,0.3,3.2-0.3,4.4-1c0.3,2.5-2.5,3.5-0.5,4.3c0.3-1.9,4.1-1.4,4.2,0.5C571.3,610.1,571.3,613.4,567.4,611.7z M595.6,112.7
        c-0.1,0.3-0.1,0.5-0.2,0.8l-3.4-0.6C593,112.2,594.7,111.9,595.6,112.7z M578.4,110.2l-2.7-0.5c1.3-1,1.9-3.2,4.1-2.7
        C578.1,107.9,578,109,578.4,110.2z M586.7,616.8c0,0.9,1.7-0.7,1.7-1.2c-0.1-0.1-0.1-0.1-0.1-0.1c0.1,0,0.1,0,0.1,0.1
        c0.8,0.8,4.9,6.1,0.9,2.9C588.3,617.7,586.7,618.5,586.7,616.8z M587.2,624.1c4.1,1.5,4.4-2,8.6-0.5
        C597.2,628.5,587,626.3,587.2,624.1z M596.5,617.9c-5.8,1.2-2.3-5.1-8-3.8c-0.4,1.1-1.4,1.5-3,1.1c-0.7-5.3,5.8-1.4,8.7-1.9
        C594.5,616.1,598.5,611.3,596.5,617.9z M614.6,631.6c-5.4-1.7-7.3-0.2-9.6-2.6C607.9,626.7,612.9,629.2,614.6,631.6z M635.2,111.4
        C633.8,117.5,629.7,110.4,635.2,111.4L635.2,111.4z M744.1,133.8c0.9,1,1.8,2,3.7,2.5c-1,1.7-3,1.5-3.8,3.3c-0.9-1-1.8-2-3.7-2.5
        C741.5,136,743.8,136.6,744.1,133.8z M742.3,140.7l-0.6-0.1C741.9,140.6,742,140.6,742.3,140.7C742.3,140.6,742.3,140.7,742.3,140.7
        z M736.8,139.1c-0.4,0.1-0.7,0.2-1.1,0.4l-2-0.4C734.7,138.9,735.6,138.8,736.8,139.1z M743.2,635.4c1.3,6.3-7.7-1.3-6.4,5
        c-2.1-0.5-5.2-0.1-5.4-2.1c8.5-1.1-7.4-0.8-5-5C731.5,631.9,735.1,637.6,743.2,635.4z M726.7,642c-2.6-1.1-9.3,2-5.6-0.7
        c1.9,0.5,2.9-8.1,4.6-2.4c-0.9,0-1.7,0-1.6,1.3C725.1,640.7,726.8,640.6,726.7,642z M723.6,123.7c3,0.2,3.3,1.9,5,2.9
        c-0.7,1.5-1.6,0.4-1.4-0.4c-1.3-0.3-1.5,1.4-2.1,2.4c-1-0.6-1-1.7-2.3-2.1C723.1,125.5,723.3,124.6,723.6,123.7z M726.7,133.4
        c1.5,0.7,0.4,1.6-0.4,1.4c-0.3,1.3,1.4,1.5,2.3,2.1c-0.6,0.7-1.1,1-1.7,1l-0.7-0.1c-1.8-0.7-3.5-3.5-4.8-0.9l-0.9-0.2
        c0.4-0.7,1.5-1.4-0.1-2.1C720.6,130.6,726.7,137.3,726.7,133.4z M711.8,129.3c1.4-0.5,2.9-1,2.7,0.7
        C713.7,131.6,711.5,130.7,711.8,129.3z M715.1,133.1c-0.2,0-3.5,1.8-4.4,0.2C710,132.1,720.1,132.3,715.1,133.1z M710.5,128.9
        c-0.4,1.4-0.7,2.7-1.1,4.1C704.9,133.9,707.1,125.8,710.5,128.9z M698.9,128.7c-0.3,2.2,4.7,1.8,4.1,1.1
        C706.9,134.4,696.6,134,698.9,128.7z M694.6,123.1c1,0.6,1,1.7,2.3,2.1c-0.6,1-1.7,1-2.1,2.4c-1-0.6-1-1.7-2.3-2.1
        C693,124.4,694.2,124.5,694.6,123.1z M696.8,131c0.3,1.3-2.3,0-4.4,0.3c0,0,0,0.1,0,0.1l-0.9-0.2C693.2,129.5,696.2,128.2,696.8,131
        z M687.7,127.1c0.9,0.2,1.8,0.5,2.7,0.7c-0.2,0.9-0.5,1.8-0.7,2.7c-0.9-0.2-1.8-0.5-2.7-0.7C687.2,128.9,687.4,128,687.7,127.1z
         M663.5,124.9c-0.1,0.5-0.2,0.8-0.4,1.1l-2.6-0.5C661.5,125.2,662.5,125,663.5,124.9z M666.6,624.5c-3.7-0.5-8.3-0.2-8-3.9
        C660.8,622.2,665.5,621.9,666.6,624.5z M658.2,117.6c2.1-0.4,2.8,1.7,1,1.7c-0.4,0-1-0.4-1.4-0.4
        C655.5,119.5,657.1,117.9,658.2,117.6z M655,124.1c2.6-0.2,2.8,0.4,2.2,0.8l-3.2-0.6c-0.1-0.2-0.2-0.4-0.3-0.6
        C653.3,122.7,656.1,123,655,124.1z M641.1,121.8c0,0,0.1,0.1,0.1,0.1l-0.4-0.1C640.9,121.8,641,121.8,641.1,121.8z M646.4,638.2
        c-0.5-1.8-10-0.4-10.6-5.6C640.7,632.5,649.6,635.9,646.4,638.2z M649.7,116.8C644.3,120.7,646.3,111.4,649.7,116.8L649.7,116.8z
         M657.1,632.1c0.9,0-0.9-3-1-3c-2.7-1.5-4.7,1.4-7.2,0.6c-0.7-6.6,12.4-3.8,14.3-1.2C659.5,628.2,659.8,632,657.1,632.1z
         M661.6,629.7c2.7-2.3,6.8-3.3,8.4,1C666.6,628.8,662.8,634.3,661.6,629.7z M685.3,644.3c0.5-1.7,1.7-0.7,2.8,0.3
        C687.8,646,685.4,646.1,685.3,644.3z M691.4,640.6c1.5,0.3,2.5-1.8,2.8,0.3C694,642.4,691.5,642.5,691.4,640.6z M689.2,636
        c-0.3,1.8-2.1,1.8-2,4.2c-5.2-1.3-5.2-1.7-10.8-4.2c-0.2,2.2-4.2,0.5-1.4-0.2c2.2-0.1,1.4-3,0.6-4.3c5.8-1.5,12.8,0.2,15.7-1
        c0,1.7-0.8,4.1,0.8,4.5c1.5-4.6,10.9,1,15.4,1.9c3-0.5,4.9-2.5,7.6-3.5c-0.1,1.8,0,3.5,2.4,3.2c0-2.9,2.3-2.6,3.4-4
        c-2.4-0.2-3.4-1.6-3.8-3.4c9.7,1.8-1,7.8,4.2,10.8c-3.4,0-3.3,4.7-7.6,3.5c1.1-6-3-1.4-4.2-0.5c-0.2-1.2,0.3-2.8-1-3
        c-3.2,3.7-8.9-1.4-11-2.8C694.3,639.4,691.6,636.6,689.2,636z M722.1,654.6c-9.6-0.9-16.4-4-21.5-8.5c4,0.3,9,1.9,11.4-0.1
        C712.8,652.8,724.1,643.6,722.1,654.6z M734.7,656.2c-6.2-0.6-5.1-5.5-9.8-1.2c0.6-2.7,1.6-4.7,3.6-5.4
        C728.7,655.9,736.3,648.4,734.7,656.2z M739.8,649.6c-2.9-2.9-15.9-4.4-10-10c3.7,3.2,12.4,4.4,13.4,6.1
        C740.9,645.3,741.9,649.5,739.8,649.6z M742.4,641.1c3.7-0.7,6.7-1.3,8.2,2.5C745.9,642.9,744.3,645.4,742.4,641.1z M806.4,151.3
        c-0.3,0.4-0.7,0.8-1.1,1.1l-2.9-0.5C803.4,151.1,804.7,150.8,806.4,151.3z M787.5,145.9c1,0.6,1,1.7,2.3,2.1c5-0.6,1.9,0.5-1,1.3
        l-2.9-0.5C786.3,148.2,787.4,147.4,787.5,145.9z M771.4,145.8c0.2,0.1,0.3,0.2,0.4,0.4l-0.6-0.1C771.3,146,771.4,145.9,771.4,145.8z
         M762.1,127.1c3,0.2,3.3,1.9,5,2.9C766.9,132.5,758.8,130.3,762.1,127.1z M761,650.8l-7-0.9C752.8,642.5,762.6,646.6,761,650.8z
         M761.8,634.8c-0.8-0.3-1.2-0.6-1.4-0.8l1.4,0.3C761.8,634.4,761.8,634.6,761.8,634.8z M763.1,139.1c-0.2,0.2-1.5,3.7-2.1,2.3
        C760.3,139.8,766.1,135.8,763.1,139.1z M766.6,641.3c-0.8,2.8,1.5,3.1,2.2,4.7C765.7,646.8,763,641.4,766.6,641.3z M792,651.9
        c-1.6,2.4-3.8,4-6.4,5c-3.7-4.4-4.9,2-7.4,2c0.4,0,0.2-0.7-0.2-1.5c-0.4-0.8-0.8-1.5-0.8-1.5c0.5,0.4-6.3-0.7-8.4-1.1
        c0-1.7,0.8-4.1-0.8-4.5c-0.2,1.5-1.2,3.6-1.6,1.3c3-3.5,5.7-0.6,6.4-5c3.6,5.5,6.4,0.9,12.5,1.6c2.7,3.9,4.2,2.8,9.6,2.7
        C794.7,653.6,793.5,653.8,792,651.9z M796.7,668.7c0.1,0,0.1-1.4,0.2-1.4c-0.7,0-2.8,1.3-3,1.1C790.9,665.5,803.2,665.1,796.7,668.7
        z M795.8,655.4c3.5,0.2,1.1-4.8,0.8-5.8c7.5-0.1-1.6,9.1,6.3,5.2C802.9,660.8,796.6,658.7,795.8,655.4z M803.7,669.6
        c0-1.5,1.6-3.6,1.6-1.2C805.3,669.9,803.7,671.9,803.7,669.6z M804.2,656.5c2-3.3,6.9,3.2,3.8,3.4c0-0.9,0-1.7-1.2-1.6
        C806.1,660.5,804.4,658.2,804.2,656.5z M811,677.9C809.8,683.5,804.9,676.8,811,677.9L811,677.9z M810.5,661.7
        c1.6,3.6-1.4,6.1,0.3,7.4c1.7-2.8,7.7,1.5,6.3,5.3c-2.3-0.9-3.9,1.6-4.2-0.6c4.5-2-6.1-2.7-6.3-5.3
        C811.7,667.6,807,662.9,810.5,661.7z M908.8,687.5c-2.6,1.3-15.2,4.2-15.3-2.3c8.6,4.2,2.1-1.9,7.6-3.3c0.1,2.2,4.9,7,6.5,3.9
        c-1-0.5-2.7-0.4-2.6-1.8l4.2,0.6C909.1,685.5,909,686.5,908.8,687.5z M910.2,687.7c1.6,0.5,4,0.3,3.9,2.1
        C912.6,689.3,910.2,689.4,910.2,687.7z M913.9,691.2c1.8,0.5,5.1-5.2,5.8-0.6C917.9,690.1,913.8,694.6,913.9,691.2z M856.3,130.3
        c1.5,0.7,0.4,1.6-0.4,1.4c-0.6,1.1,0.2,1.4,1,1.8c-0.4,1.3-2,0.6-3.1,0.6c0.6-1.1-0.2-1.4-1-1.7
        C853.6,130.9,855.8,132.1,856.3,130.3z M828.7,654c-4.9-1-0.3-1,2.8,0.4c0,0,0.5-2.1,1.6-1.2c0.4,0.3-1.9,2.7-1.8,2.7
        C830.1,655.6,828.9,654,828.7,654z M827.4,156.5c0.4-0.1,0.7-0.2,1-0.4c-0.1,0.2-0.2,0.4-0.3,0.5L827.4,156.5z M837.8,659.6
        c-2.8,0-2.5-2.3-3.8-3.4c-2.2,3.9-1.2-2.4,1.6-1.3C835.5,657.2,839,656.5,837.8,659.6z M837.3,653.7c3.1,1.7,6.8-4.2,7.2-0.5
        c-1.6,0.1-2.4,1.3-3.2,2.5c1,0.5,2.7,0.4,2.6,1.8C841.4,656.4,838.1,656,837.3,653.7z M846.2,660.8c0-0.9,0-1.7-1.2-1.6
        c1.2,3.2-3.2,6.5-3.2,2.5c1.7-0.1,1.1-1.5,0.4-2.9C844.8,657.8,850.7,658.1,846.2,660.8z M853.7,651.3l3.7,0.7c0,0,0,0.1,0,0.1
        C856.1,652.5,854.7,652,853.7,651.3z M844.5,146c-3.9,5.1-7.5-1.6-11,2.7c-1.8-1.2,1-1.6,1.2-4.1c-4.5-1.2-6.3,2.4-11.6-0.4
        c1.7-1.7,4.2-1.9,7.5-0.8c-2.7-6.2-8.4-0.2-11.6-0.4c-2.3-4.3,2.4-7.7,5.4-8.7c2.5,0.9-0.3,4.8,2.9,5.2c0.1-1.2,0.9-1.1,1.7-1
        c0.2-1.6-1.1-1.2-1.4-0.4c-3-7.9,9.4,2.2,9.7-3.1c3.7,4.4,9.1,5.3,14.9,4.3c4.8,1.1,3.1,5.9,6.6,7.8c-1.5,3.2-5.3-1.4-3.3-3.9
        c-6.1-0.4-9.7-2.3-16.1-0.2c-0.1,1.2,1,1.7,0.6,3.1c4-4.6,7.5,2.7,13,0.8c-2,6.3,5.7,0.9,6,4.7C852.7,153.6,847.9,150.5,844.5,146z
         M859.2,145.8c0.6-1.1-0.2-1.4-1-1.7c0.7-1.5,2.8-0.4,4.5-0.2C862.5,146.3,860.9,146.1,859.2,145.8z M868.7,662.5
        c1.6,3.8-5.4,3.5-4.4,0.8c0.9,0,1.7,0,1.6-1.2C866.8,662.2,867.7,662.4,868.7,662.5z M876.9,165.7l-2.8-0.5c0-0.1,0.1-0.2,0.1-0.3
        C875.1,165.1,876,165.4,876.9,165.7C876.9,165.7,876.9,165.7,876.9,165.7z M879.5,656.7c-0.3-0.3-0.4-0.5-0.5-0.8l1,0.2
        C879.8,656.3,879.6,656.5,879.5,656.7z M886.1,149.2c1.6,9.2-3.1-2.5-5.6,4.2c-1-1.2-1.9-2.3-2.7-0.8c-1.5-4.2-3.1-1.8-5.6-6
        c-1.6-0.2-1.1,1.1-0.4,1.4c-1.5,2-3.4,0-2.3-2.1c-2.7-0.8-2.8,3.2-6.2,1.1c1-3.2-1.2-4.7,1-8.5c2.5,0.3,2.4,2.1,1,1.7
        c4.1,4.9,9.8,6.4,14.3,1.2c1.6,0.8,0.6,3.1-0.8,2.7c2.6,2.6,15.4,4.6,19,5.5C901.9,155.6,887.4,152.7,886.1,149.2z M899.5,665.5
        c1.5,0.4,2.5-1.8,2.8,0.4C902,667.4,899.6,667.4,899.5,665.5z M906.1,171.1l-1-0.2c-1.8-0.5-3.3-1.9-0.8-3.2
        c-0.7,0.4,3.6,2.6,3.7,2.5C907.8,170.9,907,171.2,906.1,171.1z M910,142.9c-2.7,2-8.9-2.4-11.6-0.4c-5.1-2.5-6.7-4.3-11.2-1.8
        c-3-6.1-21.1-4.2-26.1-6.1c-3.3-2.3,4-2.1,4.3-4.7c0.3,2.8,3.8,1.1,4.1,1.2c3.5,1.2,6,6.1,10.3,0c1.3,0.4,0.6,2,0.6,3.1
        c8.4,0.1,15.1,2.1,22.1,4.9c4.7,0.4,4.8-4.4,8.9-0.3c-7.1,1.7,1.3,3.3,6,4.7C916,146.9,911.2,145.2,910,142.9z M915.9,157.9
        c1.1-1.5,2.5-2.3,4.9-1.5c-2.3-4.5,4.8-3.4,9.3-1.7c-0.3,0.9-0.5,1.8-0.8,2.7c1.1,0.6,1.4-0.2,1.7-1c1.7,1.8-1,1.9-0.2,5.8
        C926,164.2,920.2,159.6,915.9,157.9z M930.8,665.8c-0.1-0.1-0.1-0.2-0.2-0.3l0.6,0.1C931.1,665.7,930.9,665.8,930.8,665.8z
         M932.7,165.7c-0.4,0-1-0.5-1.4-0.4c-2.4,0.4-0.8-1.1,0.4-1.4C933.8,163.6,934.5,165.8,932.7,165.7z M932.1,162.6
        c0.1-3.2,3.2-1.2,3.9-3.3c1.1,1.3,0.5,3.5,3.3,3.9C938.6,166.3,934.3,162.5,932.1,162.6z M944.7,676.8c-0.9-0.1-1.7,0-1.6,1.2
        c0.8,0.1,1.4,1.4-0.2,1.4c-1-1.8,0-7.6,0.7-4.3C944.4,675.3,945.2,675.6,944.7,676.8z M941.9,149.3c-2.6,1.5-7.3-0.8-11.2-1.8
        c-1.7-2.2-6.6,1-10.8-3.2c7.6-0.7,12.5,0.5,16.6,3.4c1.3,0.3,1.5-1.3,2.2-2.3c4,2.2,5.6,1.5,6.4,3.3
        C944.1,152.2,943.5,150.1,941.9,149.3z M953.7,682.6C946.1,683,952.8,678,953.7,682.6L953.7,682.6z M960.1,172.4
        c-1.7-1.3-7.5-0.5-6.4-3.4c-1.3,0.7-7.6,1.4-5-3c4.2,3.9,8.2-0.4,12.4-2.2C965.4,166.9,963.6,169.6,960.1,172.4z M964.9,675.5
        c2.4,0.3,3.4,1.7,3.7,3.5C966.2,678.8,965.2,677.4,964.9,675.5z M969.9,672.9l3.2,0.6C972,674.2,971,673.6,969.9,672.9z
         M992.2,173.1c-2-3.1-1.8-0.4-5.4-1.6C987.7,170,994.8,168.2,992.2,173.1z M998.8,678.2l1.3,0.2
        C1000.2,679.8,999.2,678.7,998.8,678.2z M1092.3,720.9c-1.6-0.5-4-0.5-3.9-2.2C1090,719.2,1092.4,719.2,1092.3,720.9z M1013,702.5
        c0.2-1.8-1.4-2.3-0.6-4.5c-3.2,1.9-1.7-0.4-5.5-0.9c-0.5,1.1-0.5,2.8-1.9,2.6c0.4-2-0.1-3.3-0.6-4.5c3.7,0.6,7.4,1.2,11.1,1.9
        C1014.8,699.1,1014.9,702.4,1013,702.5z M1016.6,689.9C1009.3,692.7,1014.1,684.6,1016.6,689.9L1016.6,689.9z M1015.2,180
        c-1.3,3.8-8.9-4-10.3-0.1c-3.7-3.7,3.2-5,4.7-6c1,1.4,1.3,3.1,1.5,4.9c1.6-3.3,3.8-3,10.3,0.1C1019.2,182.1,1012.1,176.9,1015.2,180
        z M1019.4,690.3c4.6-0.1,3.8,3.4,6.9,1.2C1026.5,697.6,1018.7,694.7,1019.4,690.3z M1024.5,178.3c4.6-2,5.5,4.5,8.1,2.4
        C1036,184,1020.8,185.7,1024.5,178.3z M1066.4,691.1c-0.3-0.1-0.4-0.3-0.6-0.4l2.9,0.5C1068.4,691.5,1067.8,691.5,1066.4,691.1z
         M1088.4,703.9c0.2,3.1-6,2-1.7,1.2c-0.2-5.3,4.8-6.9,4.7-2.1C1090.3,703.2,1088.7,702.6,1088.4,703.9z M1105.2,698.2
        c0-0.1,0.1-0.1,0.1-0.2l2.4,0.5c0.1,0.1,0.2,0.2,0.2,0.3C1107.7,700.2,1105.2,700.1,1105.2,698.2z M1147,712.2
        C1152.5,708.8,1150.1,718.3,1147,712.2L1147,712.2z M1156.4,708.2c-1.2,0-1.8-0.4-2.1-1l2.2,0.4L1156.4,708.2z M1175.9,195.2
        c2,3.1,1.7,0.4,5.4,1.7c-1.3,1.8-3.1,2.8-4.4,4.6c-2.3-0.3-1.9-2-3.6-2.6C1174.4,198,1175.4,197.1,1175.9,195.2z M1180.2,724.9
        c-2.1-1.4-13.7,3.6-14.8-4.6c4.1,2.1,4.7-1.4,7.5-1.4C1173,724.5,1184.9,721.1,1180.2,724.9z M1182.3,715c-1-1.3-1.9-2.4-2.9-3.2
        l4,0.7C1182.9,713.1,1182.5,713.9,1182.3,715z M1191.4,714l1,0.2c-1.1,2.6-2.5,4.7-6.2,3.1C1187.2,715,1190.6,716.7,1191.4,714z
         M1195.3,728.1l-9.6-2.1C1187.2,721.1,1196,723,1195.3,728.1z M1207.8,717.5c-0.6-0.2-1.3-0.5-1.9-0.8l2.5,0.5
        C1208.2,717.3,1208,717.4,1207.8,717.5z M1224.2,720.1c-0.5,0-0.9-0.1-1.4-0.3L1224.2,720.1z M1222.4,729.6c1.5,0.5,2.6-1.6,2.7,0.6
        C1224.8,731.7,1222.3,731.5,1222.4,729.6z M1223.1,732.8c0,0.1,4.8,0.2,4.4-0.5C1230,736.4,1222.2,734.9,1223.1,732.8z M1254.6,223
        c-0.7,1.5-2.8,0.4-4.5,0.1C1250.9,221.5,1253,222.7,1254.6,223z M1238.1,724.2c1.6,0.6,4,0.7,3.8,2.4
        C1240.3,726,1237.9,726,1238.1,724.2z M1235.1,737c1.9-3.6-6.7-0.5-4.8-4.1c3.9,0.2,6.7,0.1,8.2,1.9
        C1236.7,734.4,1236.7,737,1235.1,737z M1250.2,740.5c-5-2.7-5.8-0.6-9.3-3.6c-0.7,1.5-3.1,2.9-3.1,0.8c1.1-0.1,1.9-0.8,2-2.5
        c4.5,2.2,6.2-0.2,8.9-0.9C1246.9,737.8,1250.8,737.7,1250.2,740.5z M1246.3,726.2c0,1.6,2.5,1.6,2.1,3.5c-2.2,3.9-1.3-3.8-5.2-2.7
        c0.8-0.8,1.6-1.6,2.1-2.9l14.3,2.7c0.7,1.3,1.9,2.4,3.2,3.4C1257.9,732,1252.4,724,1246.3,726.2z M1258.4,736.5
        c4.8,1.7,10.5,2.9,11.3,7.2C1264.2,742.3,1260.4,740,1258.4,736.5z M1297.4,743.2c2.4,0.5,3.2,2,3.4,3.9
        C1298.4,746.5,1297.5,745,1297.4,743.2z M1306.6,747c-0.9-1-2.6-0.4-2.4-2.1c-0.1,0.9,1.8-0.5,1.9-0.9c-0.1-0.1-0.1-0.1-0.1-0.1
        c0.1,0,0.1,0.1,0.1,0.1C1306.8,744.9,1310.1,750.7,1306.6,747z M1316.5,748.2c-0.7,1.4-3.2,2.8-3.1,0.7c2.7,0.7,2.8-3.4,6.2-1.3
        C1319,750.2,1317.8,750.2,1316.5,748.2z M1322.3,748.3c0.3-0.1,2-2.2,3.1-0.7C1326.2,748.6,1316.2,750,1322.3,748.3z M1333,757.2
        c-0.2,0.8-1.5,1.3-1.4-0.4C1335.4,750.8,1336.8,762.9,1333,757.2z M1349.7,760.5c-3.6,1.1-7.5,1.6-13-0.7c-0.2-4.6,3.7-1.6,6.2-1.2
        c0.6-2.4,2.1-3.3,3.9-3.4c-0.5,2.2-1.6,4.8,1.5,5C1348.6,759.3,1349.9,758.8,1349.7,760.5z M1352.4,761.3c2.3,0.6,3.2,2.1,3.2,4
        C1353.6,764.5,1350.9,764.1,1352.4,761.3z"/>
            </clipPath>
        </defs>
        <image className='aboutusmainpage'
        href={process.env.PUBLIC_URL + '/images/bg/imgee.jpeg'} 
        x="0" 
        y="-200" 
        width="800px" 
        height="800px"
        style={{ clipPath: "url(#svgPath)" }} 
      /></svg>
    </div></div>
    
    
    <div class="col-md-4 vikkar" >
    <figure>
  <div class="c"></div>
  <div class="c2"><i></i><i></i><i></i><i></i><i></i><i></i><i></i><i></i><i></i><i></i><i></i><i></i><i></i><i></i><i></i><i></i><i></i><i></i><i></i><i></i><i></i><i></i><i></i><i></i><i></i><i></i><i></i><i></i><i></i><i></i><i></i><i></i><i></i><i></i><i></i><i></i></div>
  <div class="c3"></div>
  <div class="c4"></div>
  <div class="c5"></div>
  <div class="c6"></div>
</figure>
    <div>
    <h1 className='newakefont cort'>{translations.aboutustitle}</h1>
    </div>
    <div>
    <span className='newakefont spanaboutus'>{translations.aboutuscontent}</span>
    </div>

    </div>
  </div>
</div>

                <FooterFour />
            </main>
        </>
    )
}

export default AboutUs;
