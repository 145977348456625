
import React, { useEffect,useContext } from "react";
import { useSpring, animated } from 'react-spring';
import { Link } from 'react-router-dom';
import LanguageContext from '../../LanguageContext';

const AstralServiceDsg = () => {
  const { translations } = useContext(LanguageContext);
  const animationProps = useSpring({
    from: { opacity: 0, transform: 'translateX(40%)' },
    to: { opacity: 1, transform: 'translateX(0)' },
    config: { duration: 1000 },
  });

  useEffect(() => {
    const indexes = document.querySelectorAll('.indexes li');
    const tabs = document.querySelectorAll('.tabs li');
    const contents = document.querySelectorAll('.tab-content');
  
    function reset() {
      for (let i = 0; i < tabs.length; i++) {
        indexes[i].style.borderColor = 'transparent';
        tabs[i].style.zIndex = 0;
        tabs[i].classList.remove('active');
        contents[i].classList.remove('active');
      }
    }
  
    function showTab(i) {
      indexes[i].style.borderColor = 'rgba(211,38,38,0.6)';
      tabs[i].style.opacity = 1;
      tabs[i].style.zIndex = 5;
      tabs[i].classList.add('active');
      contents[i].classList.add('active');
    }
  
    function activate(e) {
      if (!e.target.matches('.indexes li')) return;
      reset();
      showTab(e.target.dataset.index);
    }
  
    // İlk sekmenin otomatik olarak seçili gelmesi için init fonksiyonunu burada çağırın
    showTab(0);
  
    window.addEventListener('click', activate, false);
  
    return () => {
      window.removeEventListener('click', activate, false);
    };
  }, []); // Boş bağımlılık dizisi ile useEffect

  return (
      <div className="unordcontainer">
      <div class="barr-container">
      <div class="barr" style={{marginTop:"10px"}}></div>
      <div class="barr2" style={{marginTop:"7px"}}></div>
      <div class="barr" style={{marginTop:"16px"}}></div>
      <div class="barr2" style={{marginTop:"2px"}}></div>
      <div class="barr" style={{marginTop:"5px"}}></div>
      <div class="barr2" style={{marginTop:"12px"}}></div>
      <div class="barr2" style={{marginTop:"5px"}} ></div>
      <div class="barr" style={{marginTop:"9px"}}></div>
      <div class="barr2" style={{marginTop:"5px"}}></div>
    </div>

    <div style={{marginTop:"40px",textAlign:"center",marginLeft:"auto",marginRight:"auto", maxWidth:"950px"}}>
    <div className="lefttex">
    <span className="newakefont headingthird" style={{fontWeight:"600",opacity:"0.2"}}>{translations.servtitle}</span>

    </div>
    <div>
    <div>
    <div className="moon">
    <section className="sec">
      <ul class='indexes'>
        <li data-index='0'>01</li>
        <li data-index='1'>02</li>
        <li data-index='2'>03</li>
        <li data-index='3'>04</li>
      </ul>
      <ul class='tabs lefttex'>
        <li class='tab'>
          <article class='tab-content'>
            <h3 className="newakefont">{translations.navtwoone}</h3>
            <p className="newakefont">{translations.servairexp}</p>
            <div>
            <div className="btnnn btnnn-1 newakefont">
            <Link to="/air-freight">
              <svg>
                <rect x="0" y="0" fill="none" width="100%" height="100%"/>
              </svg>
              {translations.Buttonmore}
             
            </Link>
          </div>
    </div>
          </article>
          <div class='tab-image'><img src={process.env.PUBLIC_URL + '/images/bg/planee.png'}/></div>

        </li>
        <li class='tab '>
          <article class='tab-content'>
            <h3 className="newakefont">{translations.navtwotwo}</h3>
            <p className="newakefont">{translations.servshipexp}</p>
            <div>
            <div className="btnnn btnnn-1 newakefont">
            <Link to="/sea-freight">
              <svg>
                <rect x="0" y="0" fill="none" width="100%" height="100%"/>
              </svg>
              {translations.Buttonmore}
            </Link>
          </div>
    </div>
          </article>
          <div class='tab-image'><img src={process.env.PUBLIC_URL + '/images/bg/ship.png'}/></div>
        </li>
        <li class='tab'>
          <article class='tab-content '>
            <h3 className="newakefont">{translations.navtwothree}</h3>
            <p className="newakefont">{translations.servroadexp}</p>
            <div>
            <div className="btnnn btnnn-1 newakefont">
            <Link to="/road-freight">
              <svg>
                <rect x="0" y="0" fill="none" width="100%" height="100%"/>
              </svg>
              {translations.Buttonmore}
            </Link>
          </div>
    </div>
          </article>
          <div class='tab-image'><img src={process.env.PUBLIC_URL + '/images/bg/truck.png'}/></div>
        </li>
        <li class='tab'>
          <article class='tab-content'>
            <h3 className="newakefont">{translations.navtwofour}</h3>
            <p className="newakefont">{translations.servwarehouse}</p>
            <div>
            <div className="btnnn btnnn-1 newakefont">
            <Link to="/warehouse">
              <svg>
                <rect x="0" y="0" fill="none" width="100%" height="100%"/>
              </svg>
              {translations.Buttonmore}
            </Link>
          </div>
              </div>
          </article>
          <div class='tab-image'><img src={process.env.PUBLIC_URL + '/images/bg/box.png'}/></div>
        </li>
      </ul>
    </section>
  </div>
  </div>
  </div>
  </div>  
  </div>
  );
};

export default AstralServiceDsg;


